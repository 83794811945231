import React, { useContext } from 'react'
import { handleWaring } from '../../services/isValid'
import { requestContext } from '../../context/requestContext'
import { exportToExcel } from '../../services/spreedsheet'
export const handleExportXLS = ({ setInfoRequest, data, fileName, headers }) => {
	const result = exportToExcel(data, fileName, headers)
	if (!result) {
		return setInfoRequest(handleWaring('Há dados faltando para gerar o Excel, entre em contato com o adimistrador'))
	}
}
const ExportCSV = ({ data, headers, fileName }) => {
	const { setInfoRequest } = useContext(requestContext)
	if (!data || !headers) {
		return <></>
	}

	return (
		<div className='btn btn-success mr-2' onClick={() => handleExportXLS({ setInfoRequest, data, fileName, headers })}>
			Gerar Excel <i className='fa fa-table'></i>
		</div>
	)
}

export default ExportCSV