import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formatDate } from '../../../services/dateHandless'
import { ProgressBar } from '../../progressBar'
import { formatPercentage } from '../../../services/stringHandless'
import { find } from '../../../services/arrayHandless'
import { handleWaring } from '../../../services/isValid'

const ListContructionPlan = ({ data, itemsSelected, setItemsSelected, handleOpenOversights }) => {
	const [items, setItems] = useState(null)
	const { setInfoRequest } = useContext(requestContext)
	useEffect(() => {
		if (Array.isArray(data)) {
			setItems(data)
		}
	}, [data])
	if (!Array.isArray(items)) {
		return <div></div>
	}
	const onChangeCheckBox = ({
		target: { value }
	}) => {
		const item = JSON.parse(value)
		if (!itemsSelected?.length) {
			return setItemsSelected([{ ...item }])
		}
		const { data: findItem } = find(itemsSelected, 'pl_ob_cod', item.pl_ob_cod)
		if (findItem) {
			return setItemsSelected(itemsSelected.filter(pl_ob => pl_ob.pl_ob_cod !== item.pl_ob_cod))
		}
		if (!itemsSelected.map(i => i.rod_cod).includes(item.rod_cod)) {
			return setInfoRequest(handleWaring('Você só pode selecionar planos de obras da mesma concessionária e da mesma rodovia'))
		}
		return setItemsSelected([...itemsSelected, { ...item }])
	}
	const checked = (cod) => {
		if (!itemsSelected.length) {
			return false
		}
		return itemsSelected.map(i => i.pl_ob_cod).includes(cod)
	}
	return (
		<div className='col-12 cardBg'>
			{items.map(item => (
				<div key={item.pl_ob_cod} className='col-12 p-3 mb-1 customBorder' style={{ '--color-var': item.st_cor_valor }}>
					<div className='row justify-content-between'>
						<div className='col-sm-12 col-md-6  mb-2 ncTitle' style={{ color: item.st_cor_valor, cursor: 'pointer' }}
							onClick={() => onChangeCheckBox({ target: { value: JSON.stringify(item) } })}
						>
							<input
								type='checkbox'
								value={JSON.stringify(item)}
								id='flexCheckChecked'
								checked={checked(item.pl_ob_cod)}
								onChange={onChangeCheckBox} />
							&nbsp;
							# {item?.sisf_sinc_int_amp_id || item.pl_ob_cod} ({item.st_desc})
						</div>

						{item.sinc_st_desc && (
							<div className='col-6 col-md-3  mb-2'>
								<div className='badge text-light bg-dark text-center p-2'											>
									{item.sinc_st_desc} no SISF
								</div>
							</div>
						)}

						{item.tip_ped_venda_desc && (
							<div className='col-6 col-md-3 mb-2 d-flex justify-content-end'>
								<div
									className='badge badgeMax text-light bg-secondary text-center p-2'
								>
									{item.tip_ped_venda_desc}
								</div>
							</div>
						)}
					</div>
					<div className='row'>
						<div className='col-sm-6 col-md-8 mb-2'>
							<span className='text-secondary mr-2'>
								Concessionária:
							</span>
							{item.conc_nome}
						</div>
						<div className='col-sm-6 col-md-2 mb-2'>
							<span className='text-secondary mr-2'>
								Lote:
							</span>
							{item.lot_conc_desc}
						</div>
					</div>
					<div className='row'>
						<div className='col-md-8 col-sm-12 mb-3'>
							<span className='text-secondary'>Rodovia:</span> {item?.rod_km} - {item.rod_desc}
						</div>
						<div className='col-md-4 col-sm-12 mb-2 '>
							<span className='text-secondary mr-2'>
								Responsável:
							</span>
							{item.name}
						</div>
						{(item.pl_ob_data_program_ini && item.pl_ob_data_program_fim) ? (
							<>
								<div className='col-md-4 col-sm-12 mb-2'>
									<span className='text-secondary mr-2'>
										Programada Iniciar:
									</span>
									{formatDate(item.pl_ob_data_program_ini)}
								</div>
								<div className='col-md-4 col-sm-12 mb-2'>
									<span className='text-secondary mr-2'>
										Programada Terminar:
									</span>
									{formatDate(item.pl_ob_data_program_fim)}
								</div>
							</>
						) : null}
						<div className='col-md-4 col-sm-12 mb-2 '>
							<span className='text-secondary mr-2'>
								Último Retorno:
							</span>
							{formatDate(item.updated_at)}
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4 col-sm-12 mb-2 '>
							<span className='text-secondary mr-2'>
								Ac Ant.:
							</span>
							{formatPercentage(item.prog_ob_por_cen_acul_ant)}
						</div>
						<div className='col-md-4 col-sm-12 mb-2 '>
							<span className='text-secondary mr-2'>
								Ac:
							</span>
							{formatPercentage(item.prog_ob_por_cen_acul)}
						</div>
						<div className='col-md-4 col-sm-12 mb-2 '>
							<span className='text-secondary mr-2'>
								Progresso:
							</span>
							{formatPercentage(item.prog_ob_por_cen_progress)}
						</div>
					</div>
					<div className='col-12'>
						<ProgressBar value={[
							{
								value: item.prog_ob_por_cen_acul_ant,
							},
							{
								value: item.prog_ob_por_cen_progress,
							},
							{
								value: 100 - item.prog_ob_por_cen_acul
							}]}
						/>
					</div>
				</div>
			))}
			{itemsSelected.length ? (
				<div className="floating-button d-none d-sm-block">
					<button type="button" className="btn btn-info mr-1"
						onClick={handleOpenOversights}>
						<i className="fa fa-chevron-right"></i>
						&nbsp; Visualizar ({itemsSelected.length})
					</button>
				</div>
			) : <div className="floating-button">
				<button disabled type="button" className="btn btn-info">
					<i className="fa fa-chevron-right"></i>
				</button>
			</div>}
		</div>
	)
}
export default ListContructionPlan
