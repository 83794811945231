import { useContext, useRef } from "react"
import { find } from "../../../services/arrayHandless"
import { generateBase64, getExt } from "../../../services/files"
import getLatLng from "../../../services/geolocation"
import { getToday } from "../../../services/dateHandless"
import { formContext } from "../../../context/formContext/index.js"
import { ImageBox } from "./ImageBox/index.js"
import { getUniqueRandomInt } from "../../../services/randomId/index.js"
import { requestContext } from "../../../context/requestContext/index.js"
import { handleError } from "../../../services/isValid/index.js"

export const PhotoSection = () => {

	const { bases64: items, setBases64, } = useContext(formContext)
	const { setInfoRequest, deleteItemOversightPhotos } = useContext(requestContext)
	const input_file_ref = useRef(null)

	const handleAttach = () => {
		input_file_ref.current.click()
	}
	const onChangeFile = async ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return
		}
		const newItems = []
		for (const itemFile of files) {
			const { name } = itemFile
			const type = getExt(name)
			const types_valid = [{ type: 'jpeg' }, { type: 'jpg' }, { type: 'png' }]
			const { data: find_type } = find(types_valid, 'type', type)
			if (find_type) {
				const position = await getLatLng()
				const base64 = await generateBase64(itemFile)
				newItems.push({
					pvs_loc_foto_path: base64,
					id: getUniqueRandomInt(),
					name: name,
					pvs_loc_foto_lat: position.lat,
					pvs_loc_foto_lng: position.lng,
					pvs_loc_foto_legenda: `Foto tirada dia ${getToday()}.`,
				})
			}
		}
		if (!newItems.length) {
			return
		}
		if (items?.length) {
			return setBases64([...newItems, ...items])
		}
		setBases64([...newItems])
	}

	const handleRemove = async (item) => {
		try {
			const { id, pvs_loc_foto_cod, pvs_loc_foto_legenda } = item
			if (pvs_loc_foto_cod && pvs_loc_foto_legenda) {
				const confirm = window.confirm(`Você deseja mesmo remover a foto ${pvs_loc_foto_legenda}?`)
				if (!confirm) {
					return
				}
				const response = await deleteItemOversightPhotos({
					id: pvs_loc_foto_cod
				})
				if (!response) {
					return
				}
			}
			const newArrFiltered = items.filter(item => item.id !== id);
			setBases64(newArrFiltered)
		} catch (error) {
			setInfoRequest(handleError(String(error)))
		}
	}

	return (
		<>
			<h5 className="mb-2">Passo 1/4</h5>
			<h4 className="mb-2 mt-2">Fotos</h4>
			<ImageBox items={items} handleAttach={handleAttach} handleRemove={handleRemove} />
			<input
				name="file_attachments"
				className="d-none"
				type="file"
				onChange={onChangeFile}
				ref={input_file_ref}
				multiple
			/>
		</>
	)
}