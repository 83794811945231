import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../../context/formContext'
import FilterStatusOversight from '../statusOversight'
import Dealership from '../../dealership'
import { LoadMaxDate, loadMinDate } from '../../../../services/dateHandless'
import SelectHighway from '../../selectHighway'
import { requestContext } from '../../../../context/requestContext'
import SelectSyncStatus from '../../selectSyncStatus'
import SelectGroups from '../../selectGroups'

const FormoversightByDate = ({ typeDate, min, max, syncStatus }) => {
	const { state, onChange } = useContext(formContext)
	const { getAllItemHighway, getAllItemDealership } = useContext(requestContext)
	const { serv_cat_prazo_from, serv_cat_prazo_to, type_date, conc_cod } = state
	const [minDate, setMinDate] = useState(null)
	const [maxDate, setMaxDate] = useState(null)
	useEffect(() => {
		if (min) {
			loadMinDate(setMinDate)
		}
	}, [min])
	useEffect(() => {
		if (max) {
			LoadMaxDate(setMaxDate)
		}
	}, [max])
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		}
	}, [conc_cod])
	return (
		<div className="row">
			<div className="col-md-6 col-sm-12">
				<Dealership />
			</div>
			<div className="col-md-6 col-sm-12  mb-3">
				<SelectHighway />
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Da data:</label>
				{minDate ? (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_from' value={serv_cat_prazo_from}
							max={serv_cat_prazo_to || maxDate}
							min={minDate}
							className={`form-control ${!serv_cat_prazo_from && 'is-invalid'}`}
						/>
					</div>
				) : (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_from' value={serv_cat_prazo_from}
							max={serv_cat_prazo_to}
							className={`form-control ${!serv_cat_prazo_from && 'is-invalid'}`}
						/>
					</div>
				)}
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Até a data:</label>
				{maxDate ? (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_to' value={serv_cat_prazo_to}
							min={serv_cat_prazo_from || minDate}
							max={maxDate}
							className={`form-control ${!serv_cat_prazo_to && 'is-invalid'}`}
						/>
					</div>
				) : (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_to' value={serv_cat_prazo_to}
							min={serv_cat_prazo_from}
							className={`form-control ${!serv_cat_prazo_to && 'is-invalid'}`}
						/>
					</div>
				)}
			</div>
			{syncStatus ? (
				<>
					<div className='col-md-4 col-sm-12'>
						<SelectSyncStatus />
					</div>
					<div className="col-md-4 col-sm-12">
						<FilterStatusOversight />
					</div>

				</>
			) : (
				<div className="col-md-4 col-sm-12">
					<FilterStatusOversight />
				</div>
			)}
			{typeDate && (
				<div className="form-group col-md-4 col-sm-6">
					<label>Procurar por data de:</label>
					<div className="input-group">
						<select
							name='type_date'
							onChange={onChange}
							className={`form-control ${!type_date && 'is-invalid'}`}>
							<option>Selecionar</option>
							<option value={0}>Constatação</option>
							<option value={1}>Prazo de finalzação</option>
						</select>
					</div>
				</div>
			)}
			<SelectGroups col={'col-md-4 col-sm-6'} />
		</div>
	)
}
export default FormoversightByDate