import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchContructionPlan = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getItemConstructionPlan } =
		useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		desc,
		conc_cod,
		rod_cod,
		ped_venda_date_init,
		ped_venda_date_fim,
		type_date } = state
	const onSearch = () => {
		getItemConstructionPlan({
			id: 0,
			rod_cod,
			conc_cod,
			ped_venda_date_init,
			ped_venda_date_fim,
			desc,
			type_date: type_date || 8
		})
	}

	return (
		<div className="input-group input-group-sm">
			{user?.set_nivel <= 3 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						Novo <i className="fa fa-plus"></i>
					</button>
				</div>
			)}
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={onSearch}>
					Atualizar lista
				</button>
			</div>
			<input
				type="text"
				className="form-control float-right"
				name="desc"
				value={desc}
				onChange={onChange}
				placeholder="Procurar por concessionária"
			/>
			<div className="input-group-append">
				<button className="btn btn-default" onClick={onSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchContructionPlan
