import html2canvas from 'html2canvas'

export const generateImage = async (element) => {
	try {
		const canvas = await html2canvas(element)
		if (!canvas) {
			return null
		}
		return canvas.toDataURL('image/jpeg', 0.9)
	} catch (error) {
		return null
	}
}
export const downLoadFiles = async (url) => {
	try {
		window.open(url)
		return true
	} catch (error) {
		console.log(error)
		return false
	}
}
/**
 * Generates a base64 representation of the given file.
 * @param {File} file - The file to generate base64 representation for.
 * @returns {Promise<string>} - A promise that resolves with the base64 representation of the file.
 *                             If an error occurs during the process, the promise is rejected with the error.
 */
export const generateBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
/**
 * Returns the file extension from a given filename.
 * @param {string} filename - The filename from which to extract the extension.
 * @returns {string} The file extension or an empty string if the filename is falsy.
 */
export const getExt = (filename) => {
	try {
		if (!filename) {
			return ''
		}
		if (isBase64(filename)) {
			const ext = filename.match(/data:(.*?);base64,/)[1]
			return String(ext).replace('image/', '')
		}
		return filename.split('.').pop()
	} catch (error) {
		console.log(error)
		return ''
	}
}

/**
 * Converts a base64 string back to a file and initiates the download.
 * 
 * @param {string} base64String - The base64 encoded string.
 * @param {string} filename - The filename of the downloaded file.
 */
export function base64toFile(base64String, filename) {
	try {
		// Convert the base64 string to an array of bytes
		const byteCharacters = atob(base64String)
		const byteNumbers = new Array(byteCharacters.length)
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)

		// Create a Blob object from the byte array
		const fileBlob = new Blob([byteArray], { type: 'application/octet-stream' })

		// Create a temporary URL for the Blob object
		const fileUrl = URL.createObjectURL(fileBlob)

		// Create a download link for the file
		const downloadLink = document.createElement('a')
		downloadLink.href = fileUrl
		downloadLink.download = filename

		// Add the link to the document and simulate click to initiate download
		document.body.appendChild(downloadLink)
		downloadLink.click()

		// Remove the link from the document
		document.body.removeChild(downloadLink)

	} catch (error) {
		console.log(error)
	}
}
/**
 * Formats the size in bytes into a human-readable string with appropriate units (bytes, KB, MB, GB).
 * 
 * @param {number} bytes - The size in bytes to be formatted.
 * @returns {string} The formatted size string with appropriate units.
 */
export function formatBytes(bytes) {
	if (bytes < 1024) {
		return bytes + ' bytes'
	} else if (bytes < 1024 * 1024) {
		return (bytes / 1024).toFixed(2) + ' KB'
	} else if (bytes < 1024 * 1024 * 1024) {
		return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
	} else {
		return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
	}
}
/**
 * Check if a string is a valid base64-encoded string.
 *
 * This function checks whether the provided string is a valid base64-encoded string.
 *
 * @param {string} string The string to be checked.
 * @return {boolean} True if the string is a valid base64-encoded string, false otherwise.
 */
export function isBase64(string) {
	try {
		if (!string) {
			return false;
		}

		// Extrair a parte codificada (após a vírgula)
		const file64 = string;
		const replace = file64.substring(0, file64.indexOf(',') + 1);
		let file = file64.replace(replace, '').replace(/ /g, '+');

		// Verificar se corresponde ao padrão base64
		const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
		if (!base64Regex.test(file)) {
			return false;
		}

		// Testar se a string pode ser decodificada com sucesso
		atob(file);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
}

