import React, { Children, cloneElement, isValidElement, useContext, useEffect, useRef, useState } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import { MARKER_ICONS } from '../../config/flavor'
import { formContext } from '../../context/formContext'
import { recoverCache } from '../../services/cacheManager'
import getLatLng from '../../services/geolocation'
import { requestContext } from '../../context/requestContext'
const Map = ({ onClick, onIdle, children, style, layer, polyline, ...options }) => {
	const { state, handleForm } = useContext(formContext)
	const {
		pv_loc_lat,
		pv_loc_lng
	} = state
	const ref = useRef(null)
	const [map, setMap] = useState(null)
	const [geocode, setGeocode] = useState(null)
	const [heatMap, setHeatMap] = useState(null)

	useEffect(() => {
		if (ref.current && !map) {
			const tempMap = new window.google.maps.Map(ref.current, {})
			setMap(tempMap)
			if (layer) {
				const heatData = []
				for (var i = 0; i < layer.positions.length; i++) {
					var weighted = {}
					let count = 0
					count = layer.positions[i].count
					weighted.location = new window.google.maps.LatLng(
						layer.positions[i].lat,
						layer.positions[i].lon)
					weighted.weight = count
					heatData.push(weighted)
				}
				setHeatMap(new window.google.maps.visualization.HeatmapLayer({
					data: heatData,
				}))
			}
			setGeocode(new window.google.maps.Geocoder())
		}
	}, [ref, map])
	useEffect(() => {
		if (heatMap?.setMap && map) {
			heatMap.setMap(heatMap.getMap() ? null : map)
			heatMap.set('radius', layer?.radius ? 20 : null)
		}
	}, [heatMap, map])
	useEffect(() => {
		const loadPolyline = () => {
			if (polyline && map) {
				const Polyline = new window.google.maps.Polyline({ ...polyline })
				Polyline?.setMap(map)
			}
		}
		loadPolyline()
	}, [map, polyline])
	useEffect(() => {
		if (map) {
			map.setOptions(options)
		}
	}, [map, options])
	useEffect(() => {
		if (geocode) {
			loadGeocodeAddress()
		}
	}, [geocode])
	useEffect(() => {
		if (map) {
			['click', 'idle'].forEach((eventName) =>
				window.google.maps.event.clearListeners(map, eventName)
			)

			if (onClick) {
				map.addListener('click', onClick)
			}

			if (onIdle) {
				map.addListener('idle', () => onIdle(map))
			}
		}
	}, [map, onClick, onIdle])
	const loadGeocodeAddress = async () => {
		const response = await geocode.geocode({
			location: {
				lat: pv_loc_lat,
				lng: pv_loc_lng
			}
		})
		if (response) {
			handleForm({
				pv_loc_address: response.results[0].formatted_address
			})
		}
	}
	return (
		<>
			<div ref={ref} style={style} />
			{Children.map(children, (child) => {
				if (isValidElement(child)) {
					return cloneElement(child, { map })
				}
			})}
		</>
	)
}

const Marker = (options) => {
	const [marker, setMarker] = useState()
	useEffect(() => {
		if (!marker) {
			setMarker(new window.google.maps.Marker())
		}

		return () => {
			if (marker) {
				marker.setMap(null)
			}
		}
	}, [marker])

	useEffect(() => {
		if (marker) {
			const infowindow = new window.google.maps.InfoWindow({
				content: options.content,
			})
			const icon = MARKER_ICONS.find(el => el.name === `marker_${options.icon_color}`)
			marker.setOptions(icon ? {
				...options,
				icon: icon.path
			} : options)

			marker.addListener('click', () => {
				infowindow.open({
					anchor: marker,
					shouldFocus: true
				})
			})
		}
	}, [marker, options])

	return null
}

const MyMap = ({ position, markersPhotos, markersCheckInspector, zoom, height, width, layer = null, polyline }) => {
	const { isConection } = useContext(requestContext)
	const [markersSer, setMarkersServ] = useState(null)
	const [markersCheck, setMarkersCheck] = useState(null)
	const [polylineItems, setPolylineItems] = useState(null)
	const [center, setCenter] = useState(position)
	const [centerZoom, setCenterZoom] = useState(4)
	const googleMapsApiKey = recoverCache('APY_KEY_MAP')
	const [showMarkers, setShowMarkers] = useState(true)
	useEffect(() => {
		if (layer) {
			setShowMarkers(false)
		}
	}, [layer])
	useEffect(() => {
		if (Array.isArray(markersPhotos)) {
			setMarkersServ(markersPhotos)
		} else {
			setMarkersServ(null)
		}
	}, [markersPhotos])
	useEffect(() => {
		if (Array.isArray(markersCheckInspector)) {
			setMarkersCheck(markersCheckInspector)
		} else {
			setMarkersCheck(null)
		}
	}, [markersCheckInspector])
	useEffect(() => {
		loadCenter()
	}, [position])
	useEffect(() => {
		const prepareDateToPolyLine = () => {
			if (Array.isArray(markersPhotos) && polyline) {
				const tempPolyline = {
					path: [...markersPhotos],
					geodesic: true,
					strokeColor: '#FF0000',
					strokeOpacity: 1.0,
					strokeWeight: 2,
				};
				setPolylineItems(tempPolyline)
			}
		}
		prepareDateToPolyLine()
	}, [polyline, markersPhotos])
	const loadCenter = async () => {
		if (!position) {
			const centered = isConection ? await getLatLng() : {
				lat: -23.45620955586194,
				lng: -46.641376207144965
			}
			setCenter(centered)
			setCenterZoom(4)
		} else {
			if (position.lat === 0 || position.lng === 0) {
				setCenterZoom(8)
				return setCenter({
					lat: -23.45620955586194,
					lng: -46.641376207144965
				})

			}
			setCenter(position)
			setCenterZoom(15)
		}
	}
	return (
		<div style={{ display: 'flex', height: height || '100vh', width: width || '100%', position: 'relative' }}>
			<Wrapper apiKey={googleMapsApiKey} libraries={['visualization']}>
				<Map
					center={center}
					zoom={zoom || centerZoom}
					style={{ flexGrow: '1', height: '100%' }}
					layer={showMarkers ? false : layer}
					polyline={polylineItems}
				>
					<FloatButton text={showMarkers ? <i className="fa fa-thermometer-half" aria-hidden="true"></i> : <i className="fa fa-map-pin" aria-hidden="true"></i>} controlValue={showMarkers} action={setShowMarkers} />
					{showMarkers && <Marker position={position} content={position.content} />}
					{Array.isArray(markersSer) && showMarkers && markersSer.map((item, i) => (
						<Marker key={i} position={item} icon_color={item.icon_color} content={item.content} />
					))}
					{Array.isArray(markersCheck) && showMarkers && markersCheck.map((item, i) => (
						<Marker key={i} position={item} icon_color={item.icon_color} content={item.content} />
					))}
				</Map>
			</Wrapper>
		</div>
	)
}

const FloatButton = ({ text, controlValue, action }) => {
	const style = {
		position: 'absolute',
		zIndex: 1000,
		width: '30px',
		height: '30px',
		borderRadius: '15px',
		left: 10,
		bottom: 10,
		background: '#FFFF',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer'

	}
	return (
		<div style={style} onClick={() => action(!controlValue)}>{text}</div>
	)
}
export default MyMap