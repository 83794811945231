import React from "react";
const RenderKMLocation = ({
    item,
}) => {
    return (
        <div className='col-md-4 col-sm-12 mb-3'>
            <span className='text-secondary'>Km Inicial/Final:</span>
            {item?.pv_loc_km_ini},{item?.pv_loc_km_init_mts} m /
            {item?.pv_loc_km_fim},{item?.pv_loc_km_fim_mts} m

        </div>
    )
}
export default RenderKMLocation