import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
const FormSelectDiscipline = ({
    services, servicesSubCat,
    filterServiceSubCat,
    addKey = false
}) => {
    const { onChange, state: {
        pvs_loc_foto_pedidovenda
    } } = useContext(formContext)
    return (
        <>
            {services.length ? (
                <div className="form-group col-md-6 col-sm-12">
                    <label>Anexo relacionado a alguma Disciplina:</label>
                    <select
                        onChange={filterServiceSubCat}
                        className="form-control"
                    >
                        <option value={0}>Selecionar</option>
                        {services.filter(item => item.prog_serv_obra).map((item) => (
                            <option key={addKey ? `${item.gp_cod}_${addKey}` : item.gp_cod} value={item.gp_cod}>
                                (#{item.prog_serv_obra}){' '}{item.gp_cod} | {item.serv_desc}
                            </option>
                        ))
                        }
                    </select>
                </div>
            ) : ''}
            {servicesSubCat?.length ? (
                <div className="form-group col-md-6 col-sm-12">
                    <label>Anexo relacionado a algum serviço:</label>
                    <select
                        onChange={onChange}
                        name='pvs_loc_foto_pedidovenda' value={pvs_loc_foto_pedidovenda}
                        className={`form-control ${!pvs_loc_foto_pedidovenda && 'is-invalid'}`}
                    >
                        <option>Selecionar</option>
                        {servicesSubCat.filter(item => item.prog_serv_obra).map(item => (
                            <option key={item.prog_serv_serv_cod} value={item.prog_serv_serv_cod}>
                                (#{item.prog_serv_obra}){' '}{item.serv_sub_cat_subitem_der} | {item.serv_sub_cat_desc}
                            </option>
                        ))
                        }
                    </select>
                </div>
            ) : ''}
        </>
    )
}
export default FormSelectDiscipline