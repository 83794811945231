import { colorsToChartPercentageExecution, config, labelsToChartPorcenExec } from '../../constants'
import { generateRandomColorPaletteWithBrightness, groupBy, sortBrazilianDates } from '../arrayHandless'
import { formatDate, parseFromMysqlDateToJSDate } from '../dateHandless'
import { getPartTextFromCharacter } from '../stringHandless'

const loadConfigOutros = async () => {
	const ane_pl_ob_tipo_outro = await config('ane_pl_ob_tipo_outro')
	if (!ane_pl_ob_tipo_outro) {
		return
	}
	const config_ane_pl_ob_tipo_outro = await ane_pl_ob_tipo_outro
	const { config_para_value } = config_ane_pl_ob_tipo_outro
	return Number(config_para_value)
}
const loadConfigGaleria = async () => {
	const ane_pl_ob_tipo_galeria = await config('ane_pl_ob_tipo_galeria')
	if (!ane_pl_ob_tipo_galeria) {
		return
	}
	const config_ane_pl_ob_tipo_galeria = await ane_pl_ob_tipo_galeria
	const { config_para_value } = config_ane_pl_ob_tipo_galeria

	return Number(config_para_value)
}
const loadConfigPvsStatusPedente = async () => {
	const ped_venda_status_pendente_aprovacao = await config('ped_venda_status_pendente_aprovacao')
	if (!ped_venda_status_pendente_aprovacao) {
		return
	}
	const config_ped_venda_status_pendente_aprovacao = await ped_venda_status_pendente_aprovacao
	const { config_para_value } = config_ped_venda_status_pendente_aprovacao
	return Number(config_para_value)
}
const loadConfigWhichPartToTake = async () => {
	const pl_ob_cod_which_part_to_take = await config('pl_ob_cod_which_part_to_take')
	if (!pl_ob_cod_which_part_to_take) {
		return
	}
	const config_pl_ob_cod_which_part_to_take = await pl_ob_cod_which_part_to_take
	const { config_para_value } = config_pl_ob_cod_which_part_to_take
	return Number(config_para_value)
}
/**
* @param {Array} combinedData
* @returns {Array}
*/
const getLabelToChartLine = (combinedData) => {
	if (!Array.isArray(combinedData)) {
		return []
	}
	return combinedData.map(item => {
		const [_, m, y] = String(item.date).split('/')
		return `${m}/${y}`
	})
}
/**
* @param {Array} combinedData
* @returns {Array}
*/
const prepareDataExecutedProgress = ({
	executedProgress = []
}) => {
	if (!executedProgress.length) {
		return []
	}
	const groupByDate = groupBy(executedProgress, 'apo_data')
	const groupedExecutedProgress = []
	Object.keys(groupByDate).forEach(date => {
		if (Array.isArray(groupByDate[date])) {
			const latestProgress = groupByDate[date]
				.sort((a, b) => a.apo_prog_ob_por_cen_acul - b.apo_prog_ob_por_cen_acul)[0]
			groupedExecutedProgress.push({ ...latestProgress })
		}
	})
	const formattedExecutedProgress = groupedExecutedProgress.map(item => {
		const [y, m, _] = String(item.apo_data).split('-')
		return {
			date: item.apo_data,
			yearMonth: `${y}${m}`,
			percentage: item.apo_prog_ob_por_cen_acul
		}
	})
	const groupformattedExecutedProgress = groupBy(formattedExecutedProgress, 'yearMonth')
	const fixedformattedExecutedProgress = Object.keys(groupformattedExecutedProgress).map(group => {
		const items = groupformattedExecutedProgress[group]
		return items.sort((a, b) => a.percentage - b.percentage)[items.length - 1]
	})
	return fixedformattedExecutedProgress
}
const combineDataByDate = ({
	predicted = [],
	executed = []
}) => {
	const tempAllData = []
	if (predicted?.length) {
		predicted.forEach(item => {
			tempAllData.push(formatDate(item.date))
		})
	}
	if (executed?.length) {
		executed.forEach(item => {
			tempAllData.push(formatDate(item.date))
		})
	}
	const allDates = [
		...new Set([...tempAllData])
	]
	const sortedDates = sortBrazilianDates(allDates)
	return sortedDates.map((date, index) => {
		if (index === 0) {
			const tempDate = parseFromMysqlDateToJSDate(predicted[0].date)
			return { date: tempDate, predicted: 0, executed: 0 }
		}
		const predictedValue = predicted?.length ? (predicted.find(item => formatDate(item.date) === date)?.percentage || null) : 0
		const executedValue = executed?.length ? (executed.find(item => formatDate(item.date) === date)?.percentage || null) : 0
		return { date, predicted: predictedValue, executed: executedValue }
	})
}
/**
 * Creates a dataset for a chart, combining predicted and executed progress data by aligning their dates.
 * 
 * @param {Object} params - The parameters for the function.
 * @param {Array} params.executedProgress - Array of executed progress data.
 * @param {Array} params.predictedProgress - Array of predicted progress data.
 * @returns {Object|null} The combined dataset for the chart, or `null` if an error occurs.
 * 
 * @throws Will throw an error if the executed or predicted progress arrays are empty or not provided.
 * 
 * @example
 * const dataset = createCombinedDataset({
 *   executedProgress: [
 *     { apo_data: '2024-04-25', apo_prog_ob_por_cen_acul: 10 },
 *     { apo_data: '2024-05-10', apo_prog_ob_por_cen_acul: 20 }
 *   ],
 *   predictedProgress: [
 *     { acob_date_prev_fim: '2024-04-30', acob_perc_prev: 15 },
 *     { acob_date_prev_fim: '2024-05-15', acob_perc_prev: 30 }
 *   ]
 * })
 */
const createCombinedDataset = ({ executedProgress, predictedProgress }) => {
	try {
		if (!executedProgress?.length && !predictedProgress?.length) {
			throw new Error('Executed progress or predicted progress arrays are empty')
		}
		if (!executedProgress.length && predictedProgress.length) {
			return returnDataSetChartLine({
				formattedPredictedProgress
			})
		}
		const formattedPredictedProgress = predictedProgress.map(item => ({
			date: item.acob_date_prev_fim,
			percentage: item.acob_perc_prev
		}))
		const fixedformattedExecutedProgress = prepareDataExecutedProgress({
			executedProgress
		})
		return returnDataSetChartLine({
			formattedPredictedProgress,
			fixedformattedExecutedProgress,
		})
	} catch (error) {
		console.error(error)
		return null
	}
}
const returnDataSetChartLine = ({
	formattedPredictedProgress = [],
	fixedformattedExecutedProgress = []
}) => {
	const combinedData = combineDataByDate({
		executed: fixedformattedExecutedProgress,
		predicted: formattedPredictedProgress
	})
	const labels = getLabelToChartLine(combinedData)
	const chartData = {
		labels,
		datasets: [
			{
				label: 'Acumulado Previsto',
				data: combinedData.map(item => item.predicted),
				borderColor: colorsToChartPercentageExecution[0],
				backgroundColor: colorsToChartPercentageExecution[0],
				tension: 0.3
			},
			{
				label: 'Acumulado Executado',
				data: combinedData.map(item => item.executed),
				borderColor: colorsToChartPercentageExecution[1],
				backgroundColor: colorsToChartPercentageExecution[1],
				tension: 0.3
			}
		]
	}
	return chartData
}
const geneateDataChartPizzaPorCenExec = ({
	data, state
}) => {
	const { pl_ob_cod, sisf_sinc_int_amp_id, conc_nome } = state
	try {
		const pl_ob_reais_prev = data.reduce((previous, currentValues) => previous + (currentValues.pvs_qtd * currentValues.pvs_valor), 0)
		const accumulated = data.reduce((previous, currentValues) => {
			if (currentValues.prog_serv_integracao_sisf) {
				return previous + currentValues.prog_serv_percen_acul_ant
			}
			if (currentValues?.prog_serv_qtd_acul_ant !== null) {
				return sumACCAntWhenExistQtdAnt({
					...currentValues,
					pl_ob_reais_prev,
					previous,
				})
			}
			return sumAccAntWhenNotExistQtdAnt({
				...currentValues,
				pl_ob_reais_prev,
				previous,
			})
		}, 0)
		const progress_period = data.reduce((previous, currentValues) => {
			if (currentValues.prog_serv_integracao_sisf) {
				return previous + ((currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev) - currentValues.prog_serv_percen_acul_ant)
			}
			if (currentValues?.prog_serv_qtd_acul_ant !== null) {
				const prog_serv_qtd_acul_ant = currentValues.prog_serv_qtd_acul_ant
				const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * currentValues.pvs_valor * 100 / pl_ob_reais_prev)
				return previous + ((currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev) - prog_serv_percen_acul_ant)
			}
			const prog_serv_percen_acul_decimal = currentValues.prog_serv_percen_acul / 100
			const prog_serv_qtd_acul_ant = currentValues.prog_serv_qtd_acul - (currentValues.prog_serv_qtd_acul * prog_serv_percen_acul_decimal)
			const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * currentValues.pvs_valor * 100 / pl_ob_reais_prev)
			return previous + ((currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev) - prog_serv_percen_acul_ant)
		}, 0)
		const will_be_executed = 100 - accumulated
		const chartData = [accumulated, progress_period, will_be_executed]
		const backgroundColor = generateRandomColorPaletteWithBrightness(chartData.length)
		const labels = labelsToChartPorcenExec.map((item, i) => `${item} - ${parseFloat(chartData[i]).toFixed(2)}`)
		const option_legend = [{
			label: labels[0],
			value: chartData[0]
		},
		{
			label: labels[1],
			value: chartData[1]
		},
		{
			label: labels[2],
			value: chartData[2]
		}
		]
		return {
			labels,
			datasets: [
				{
					label: `Plano de obra n° ${sisf_sinc_int_amp_id || pl_ob_cod} | ${conc_nome}`,
					data: chartData.map(item => isNaN(item) ? 0 : Number(item)),
					backgroundColor: colorsToChartPercentageExecution,
					borderColor: backgroundColor[1].brightness,
					borderWidth: 1,
				},
			],
			option_legend
		}
	} catch (error) {
		console.log(error)
		return null
	}
}
const generateItemsCalculedAcPr = ({ dataWithCodigo, pl_ob_reais_prev }) => {
	try {
		const sumCalcAcProg = dataWithCodigo.map(item => {
			let prog_serv_percen_progress,
				prog_serv_percen_acul_ant,
				prog_serv_percen_acul
			if (item?.prog_serv_integracao_sisf) {
				if (item.prog_serv_percen_acul < item.prog_serv_percen_acul_ant) {
					prog_serv_percen_progress = item.prog_serv_percen_acul_ant - item.prog_serv_percen_acul
				} else {
					prog_serv_percen_progress = item.prog_serv_percen_acul - item.prog_serv_percen_acul_ant
				}
				prog_serv_percen_acul = item.prog_serv_percen_acul
				prog_serv_percen_acul_ant = item.prog_serv_percen_acul_ant
			} else {
				const prog_serv_percen_acul_temp = (item.prog_serv_qtd_acul * item.pvs_valor * 100 / pl_ob_reais_prev)
				const prog_serv_qtd_acul_ant = item.prog_serv_qtd_acul_ant
				prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * item.pvs_valor * 100 / pl_ob_reais_prev)
				if (prog_serv_percen_acul_temp < prog_serv_percen_acul_ant) {
					prog_serv_percen_progress = prog_serv_percen_acul_ant - prog_serv_percen_acul_temp
				} else {
					prog_serv_percen_progress = prog_serv_percen_acul_temp - prog_serv_percen_acul_ant
				}
				prog_serv_percen_acul = prog_serv_percen_acul_temp
			}
			return {
				...item,
				prog_serv_percen_progress: isNaN(prog_serv_percen_progress) ? 0 : prog_serv_percen_progress,
				prog_serv_percen_acul: isNaN(prog_serv_percen_acul) ? 0 : prog_serv_percen_acul,
				prog_serv_percen_acul_ant: isNaN(prog_serv_percen_acul_ant) ? 0 : prog_serv_percen_acul_ant,
			}
		})
		return sumCalcAcProg
	} catch (error) {
		console.log(error)
		return []
	}
}
const sumProgWhenNotExistQtdAnt = ({
	prog_serv_percen_acul,
	pvs_valor,
	prog_serv_qtd_acul,
	pl_ob_reais_prev,
	previous
}) => {
	const prog_serv_percen_acul_decimal = prog_serv_percen_acul / 100
	const prog_serv_qtd_acul_ant = prog_serv_qtd_acul - (prog_serv_qtd_acul * prog_serv_percen_acul_decimal)
	const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev)
	const prog_serv_percen_acul_temp = (prog_serv_qtd_acul * pvs_valor * 100 / pl_ob_reais_prev)
	if (prog_serv_percen_acul_temp < prog_serv_percen_acul_ant) {
		return previous + (prog_serv_percen_acul_ant - prog_serv_percen_acul_ant)
	}
	return previous + (prog_serv_percen_acul_temp - prog_serv_percen_acul_ant)
}
const sumProgWhenExistQtdAnt = ({
	prog_serv_qtd_acul,
	prog_serv_qtd_acul_ant,
	pl_ob_reais_prev,
	pvs_valor,
	previous
}) => {
	console.log('pl_ob_reais_prev', pl_ob_reais_prev)
	const prog_serv_percen_acul_ant = prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev
	const prog_serv_percen_acul = prog_serv_qtd_acul * pvs_valor * 100 / pl_ob_reais_prev
	if (prog_serv_percen_acul < prog_serv_percen_acul_ant) {
		return previous + (prog_serv_percen_acul_ant - prog_serv_percen_acul_ant)
	}
	return previous + (prog_serv_percen_acul - prog_serv_percen_acul_ant)
}
const sumAccAntWhenNotExistQtdAnt = ({
	prog_serv_percen_acul,
	prog_serv_qtd_acul,
	pvs_valor,
	pl_ob_reais_prev,
	previous
}) => {
	const prog_serv_percen_acul_decimal = prog_serv_percen_acul / 100
	const prog_serv_qtd_acul_ant = prog_serv_qtd_acul - (prog_serv_qtd_acul * prog_serv_percen_acul_decimal)
	const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev)
	return previous + prog_serv_percen_acul_ant
}
const sumACCAntWhenExistQtdAnt = ({
	prog_serv_qtd_acul_ant,
	pvs_valor,
	pl_ob_reais_prev,
	previous
}) => {
	const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev)
	return previous + prog_serv_percen_acul_ant
}
const generateSumItems = (items, pl_ob_reais_prev) => {
	if (!items?.length) {
		return {
			pl_ob_reais_prev: 0,
			pl_ob_reais_exec: 0,
			pl_ob_reais_miss: 0,
			prog_serv_percen_progress: 0,
			prog_serv_percen_acul: 0,
			prog_serv_percen_acul_ant: 0,
		}
	}
	const pl_ob_reais_exec = items.reduce((previous, currentValues) => previous + (currentValues.prog_serv_qtd_acul * currentValues.pvs_valor), 0)
	const pl_ob_reais_miss = pl_ob_reais_prev - pl_ob_reais_exec
	const prog_serv_percen_progress = items.reduce((previous, currentValues) => {
		if (currentValues?.prog_serv_integracao_sisf) {
			if (currentValues.prog_serv_percen_acul < currentValues.prog_serv_percen_acul_ant) {
				return previous + (currentValues.prog_serv_percen_acul_ant - currentValues.prog_serv_percen_acul)
			}
			return previous + (currentValues.prog_serv_percen_acul - currentValues.prog_serv_percen_acul_ant)
		}
		if (currentValues?.prog_serv_qtd_acul_ant !== null) {
			return sumProgWhenExistQtdAnt({
				prog_serv_qtd_acul: currentValues.prog_serv_qtd_acul,
				prog_serv_qtd_acul_ant: currentValues.prog_serv_qtd_acul_ant,
				pl_ob_reais_prev: currentValues.pl_ob_reais_prev,
				pvs_valor: currentValues.pvs_valor,
				pl_ob_reais_prev,
				previous
			})
		}
		return sumProgWhenNotExistQtdAnt({
			...currentValues,
			pl_ob_reais_prev,
			previous
		})
	}, 0)
	const prog_serv_percen_acul = items.reduce((previous, currentValues) => {
		if (currentValues?.prog_serv_integracao_sisf) {
			return previous + currentValues?.prog_serv_percen_acul
		}
		return previous + (currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev)
	}, 0)
	const prog_serv_percen_acul_ant = items.reduce((previous, currentValues) => {
		if (currentValues.prog_serv_integracao_sisf) {
			return previous + currentValues.prog_serv_percen_acul_ant
		}
		if (currentValues?.prog_serv_qtd_acul_ant !== null) {
			return sumACCAntWhenExistQtdAnt({
				...currentValues,
				pl_ob_reais_prev,
				previous,
			})
		}
		return sumAccAntWhenNotExistQtdAnt({
			...currentValues,
			pl_ob_reais_prev,
			previous,
		})
	}, 0)
	return {
		pl_ob_reais_prev,
		pl_ob_reais_exec,
		pl_ob_reais_miss,
		prog_serv_percen_progress,
		prog_serv_percen_acul,
		prog_serv_percen_acul_ant,
	}
}
const prepareDataToBeDisplayedInTable = async (service, customizedWhichPartToTake, pl_ob_reais_prev) => {
	try {
		if (!service || !pl_ob_reais_prev) {
			throw new Error("Você precisa especificar os serviços e o valor da obra");
		}
		const { data } = service
		let dataWithCodigo = []
		const serviceWithoutCod = []
		const whichPartToTake = await loadConfigWhichPartToTake()
		dataWithCodigo = data.map(item => {
			if (!item?.pvs_obs) {
				const { data: cod } = find(serviceWithoutCod, 'serv_desc', item.serv_desc)
				if (cod) {
					return {
						...item,
						pvs_obs: '99',
						gp_codigo: '99'
					}
				}
				serviceWithoutCod.push({
					serv_desc: item.serv_desc
				})
				return {
					...item,
					pvs_obs: '99',
					gp_codigo: '99'
				}
			}
			return {
				...item,
				gp_codigo: getPartTextFromCharacter({
					input: item.pvs_obs,
					joinString: '.',
					whichPartToTake: customizedWhichPartToTake || whichPartToTake,
				})
			}
		})
		const sumCalcAcProg = generateItemsCalculedAcPr({
			dataWithCodigo,
			pl_ob_reais_prev
		})
		const group = groupBy(sumCalcAcProg, 'gp_codigo')
		return group
	} catch (error) {
		throw error
	}
}
export {
	loadConfigGaleria,
	loadConfigOutros,
	loadConfigPvsStatusPedente,
	createCombinedDataset,
	geneateDataChartPizzaPorCenExec,
	generateItemsCalculedAcPr,
	generateSumItems,
	loadConfigWhichPartToTake,
	prepareDataToBeDisplayedInTable
}