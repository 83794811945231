import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import Dealership from '../dealership'
import Highway from '../highway'
import SelectTypeContruction from '../selectTypeContruction'
import { addDots, removeDots } from '../../../services/stringHandless'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
import { requestContext } from '../../../context/requestContext'
import Spinner from '../../spinner'
const FormContructions = () => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { postItemConstructionPlan } = useContext(requestContext)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	const [showButtonSave, setShowButtonSave] = useState(true)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	useEffect(() => {
		const controllShowButtonSave = () => {
			const { pathname } = location
			if (pathname === pathname === namesScreens.CONSTRUCTION_PLANNEW) {
				return setShowButtonSave(true)
			}
			return setShowButtonSave(false)
		}
		controllShowButtonSave()
	}, [location])
	const {
		pl_ob_cod,
		pl_ob_desc_object,
		pl_ob_cod_item,
		pl_ob_tipo,
		pv_loc_pedidovenda,
		pv_loc_sentido,
		pv_loc_km_ini,
		pv_loc_km_init_mts,
		pv_loc_km_fim,
		pv_loc_km_fim_mts,
		pv_loc_obs,
	} = state
	const onBlur = ({ target: { value } }) => {
		const formated_value = removeDots(value)
		const new_value = addDots({ string: formated_value, padNumber: 3 })
		handleForm({
			pl_ob_cod_item: new_value,
		})
	}
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const payload = {
			id: pl_ob_cod,
			pl_ob_desc_object,
			pl_ob_cod_item,
			pl_ob_tipo,
			pv_loc_pedidovenda,
			pv_loc_sentido,
			pv_loc_km_ini,
			pv_loc_km_init_mts,
			pv_loc_km_fim,
			pv_loc_km_fim_mts,
			pv_loc_obs,
		}
		postItemConstructionPlan(payload)
	}
	return (
		<form className='p-3' onSubmit={onSubmit}>
			<div className='row'>
				<div className='col-12'>
					<SelectTypeContruction />
				</div>
				<Dealership />
				<Highway />
				<div className="form-group col-md-6 col-sm-12">
					<label>Nome da Obra ou do objeto:</label>
					<div className="input-group">
						<input
							type="text"
							className={`form-control ${!pl_ob_desc_object && 'is-invalid'}`}
							name="pl_ob_desc_object"
							value={pl_ob_desc_object}
							onChange={onChange}
							placeholder='Nome da Obra ou do objeto'
							disabled={disabled}
						/>
					</div>
				</div>
				<div className="form-group col-md-6 col-sm-12">
					<label>Item de serviço:</label>
					<div className="input-group">
						<input
							type="text"
							className={`form-control ${!pl_ob_cod_item && 'is-invalid'}`}
							name="pl_ob_cod_item"
							value={pl_ob_cod_item}
							onChange={onChange}
							onBlur={onBlur}
							placeholder='Item de serviço'
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
			{showButtonSave ? (
				<button className='btn btn-info' type={'submit'}>
					<i className='fa fa-save'></i> Salvar <Spinner direction={'center'} label={' '} />
				</button>
			) : null}
		</form >
	)
}
export default FormContructions
