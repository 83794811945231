import React, { useContext, useEffect, useRef, useState } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import ListServiceConstructionPlan from '../../../components/table/serviceConstructionPlan'
import { cleanArray, generateRandomColorPaletteWithBrightness, groupBy, filter } from '../../../services/arrayHandless'
import { formatCurrency, formatPercentage, getPartTextFromCharacter } from '../../../services/stringHandless'
import { ProgressBar } from '../../../components/progressBar'
import TabsConstructionPlan from '../../../components/tabs/constructionPlan'
import MonitoringTable from '../../../components/table/constructionPlan/monitoring'
import FormContructions from '../../../components/form/constructionPlan'
import FormMonitoring from '../../../components/form/constructionPlan/monitoring'
import ListAttachmentsContructionPlan from '../../../components/list/attachmentsContructionPlan'
import { parseFromMysqlDateToJSDate } from '../../../services/dateHandless'
import ListMidias from '../../../components/list/midias'
import { ChartBarDisciplines, ChartPie, LineChart } from '../../../components/charts'
import { allowedXlsExtensions, closeModal, namesCache, namesScreens, openModal } from '../../../constants'
import { importExcelToArray } from '../../../services/spreedsheet'
import { generateBase64 } from '../../../services/files'
import FormaAttachmentsConstructionPlan from '../../../components/form/attachmentsConstructionPlan'
import FormAttachMidias from '../../../components/form/attachMidias'
import { createCombinedDataset, geneateDataChartPizzaPorCenExec, generateSumItems, loadConfigPvsStatusPedente, loadConfigWhichPartToTake, prepareDataToBeDisplayedInTable } from '../../../services/constructionPlan'
import { Link, useNavigate } from 'react-router-dom'
import Spinner from '../../../components/spinner'
import { recoverCache, storageCache } from '../../../services/cacheManager'
import { handleError, handleWaring } from '../../../services/isValid'
import RenderLinkFooter from '../../../components/paginates'
import FilterDateConstructionPlanProgressService from '../../../components/form/filters/date/constructionPlanProgressService'

const ViewContructionPlan = () => {
	const navigate = useNavigate()
	const tabclassName = 'fade active show'
	const buttonServices = useRef(null)
	const buttonChart = useRef(null)
	const buttonTimeline = useRef(null)
	const buttonAttachs = useRef(null)
	const buttonMidias = useRef(null)
	const [tabservices, setservices] = useState(tabclassName)
	const [tabgraphics, setgraphics] = useState(null)
	const [tabtimeline, settimeline] = useState(null)
	const [tabinfo, setinfo] = useState(null)
	const [tabattachments, setattachments] = useState(null)
	const [tabmidias, setmidias] = useState(null)
	const defaultPlaceholderAgroupDisciplines = 'Digite aqui como deve ser agrupado o código da disciplina'
	const [placeholderAgroupDisciplines, setPlaceHolderAgroupDisciplines] = useState(defaultPlaceholderAgroupDisciplines)
	const [finanCialTotalWork, setFinancialTotalWork] = useState(0)
	const {
		postItemFixeReachedMilestone,
		getItemMonitoringProgressReachedMilestone,
		getItemServicesConstructionPlan,
		getItemMonitoringProgress,
		getItemLocationOversight,
		getItemAttachmentsConstructionPlan,
		getItemOversighttPhotos,
		getItemMonitoringProgressServiceWork,
		getItemMonitoringProgressWork,
		getItemSumConstructionPlan,
		service,
		monitoring_progress,
		oversight_location,
		data: attachments_contruction_plan,
		monitoring_progressservice_work,
		monitoring_progress_work,
		setInfoRequest,
	} = useContext(requestContext)
	const [config, setConfig] = useState(null)
	const [filterPendente, setFilterPendente] = useState(false)
	useEffect(() => {
		const loadConfig = async () => {
			const pvs_status = await loadConfigPvsStatusPedente()
			if (!config) {
				setConfig(pvs_status)
			}
		}
		loadConfig()
	}, [config])
	const [sumItems, setSumItems] = useState({
		pl_ob_reais_prev: 0,
		pl_ob_reais_exec: 0,
		pl_ob_reais_miss: 0,
		prog_serv_percen_progress: 0,
		prog_serv_percen_acul: 0,
		prog_serv_percen_acul_ant: 0,
	})
	const { state, handleForm, bases64: files, setBases64 } = useContext(formContext)
	const buttonAttach = useRef(null)
	const closeAttach = useRef(null)
	const closeMidias = useRef(null)
	const [isPressed, setIsPressed] = useState(false)
	const [whichPartToTake, setWhichPartToTake] = useState(2)
	const {
		pl_ob_cod,
		ped_venda_cod,
		apos_data_from,
		apos_data_to,
		sisf_sinc_int_amp_id,
		conc_nome,
		serv_sub_cat_desc,
	} = state
	const generateKeyCacheCustomizedWhichPartToTake = () => {
		const key_cache = Array.isArray(pl_ob_cod) ? pl_ob_cod?.join(',') : pl_ob_cod
		return `${namesCache.CUSTOMIZED_WHICH_PART_TO_TAKE}_${key_cache}`
	}
	useEffect(() => {
		const recoverCustomizedWichPartToTake = () => {

			const recover = recoverCache(generateKeyCacheCustomizedWhichPartToTake())
			if (recover) {
				const { value } = recover
				onChangeWhichPartToTake({ target: { value } })
				setPlaceHolderAgroupDisciplines(value)
			}
		}
		recoverCustomizedWichPartToTake()
	}, [pl_ob_cod])
	const [services, setServices] = useState([])
	const [attachments, setAttchments] = useState({
		items: [],
	})
	const [photos, setPhotos] = useState({
		items: []
	})
	const [servicesMonitoring, setServicesMonitoring] = useState([])
	const [chartBarDisciplines, setChartBarDisciplines] = useState(null)
	const [chartLineAdvances, setChartLineAdvances] = useState(null)
	const [chartPizzaPorCenExec, setChartPizzaPorCenExec] = useState(null)
	const [viewMonitoring, setViewMonitoring] = useState(false)
	const [disableCheckBox, setDisableCheckBox] = useState(false)
	const [openModalAttach, setOpenModalAttach] = useState(closeModal)
	const [openModalMidia, setOpenModalMidia] = useState(closeModal)
	const [reachedMilestone, setReachedMilestone] = useState(null)
	const [showReachedMileStone, setShowReachedMileStone] = useState(false)
	const fecthReachedMilestone = async () => {
		try {
			if (pl_ob_cod && ped_venda_cod) {
				const response = await getItemMonitoringProgressReachedMilestone({
					id: pl_ob_cod,
					ped_venda_cod: ped_venda_cod
				})
				if (!response?.data) {
					setShowReachedMileStone(false)
					return setReachedMilestone(null)
				}
				const { data: {
					fisc_obs,
				} } = response
				setShowReachedMileStone(true)
				setReachedMilestone(fisc_obs)
			}
		} catch (error) {
			setInfoRequest(handleError(String(error)))
		}
	}
	useEffect(() => {
		const fetchFinancialTotalWork = async () => {
			if (!ped_venda_cod) {
				return
			}
			const sumResponse = await getItemSumConstructionPlan({
				id: ped_venda_cod
			})
			if (!sumResponse?.data) {
				return
			}
			const { data: [{ total }] } = sumResponse
			setFinancialTotalWork(total)
		}
		fetchFinancialTotalWork()
	}, [ped_venda_cod])
	useEffect(() => {
		fecthReachedMilestone()
	}, [pl_ob_cod, ped_venda_cod])
	const postFixeReachedMileStone = () => {
		if (!ped_venda_cod) {
			return setInfoRequest(handleError('Não encontramos o número da Obra'))
		}
		const confirm = window.confirm(`Você deseja mesmo Gravar Marco? Essa operação vai zerar o progresso dos serviços.`)
		if (confirm) {
			postItemFixeReachedMilestone({
				id: ped_venda_cod
			})
		}
	}
	const loadConfig = async () => {
		const value = await loadConfigWhichPartToTake()
		if (!value) {
			return
		}
		setWhichPartToTake(value)
	}
	useEffect(() => {
		loadConfig()
	}, [])
	const fetchServices = () => {
		if (!ped_venda_cod || !tabservices) {
			return
		}
		if (apos_data_from && apos_data_to) {
			setInfoRequest(handleWaring('Período aplicado no filtro, se serviço(s) não forem listado(s) é porquê o Qtd. Exec. Ant., Prog. e Qtd. Exec. é 0'))
		}
		getItemServicesConstructionPlan({
			id: ped_venda_cod,
			apos_data_from,
			apos_data_to
		})
	}
	const fetchServicesMonitoring = () => {
		if (!ped_venda_cod || !tabservices) {
			return
		}
		getItemMonitoringProgressServiceWork({
			id: ped_venda_cod,
			apos_data_from,
			apos_data_to
		})
	}
	const fetchMonitoring = () => {
		if (!ped_venda_cod || !pl_ob_cod) {
			return
		}
		getItemMonitoringProgress({
			id: pl_ob_cod
		})
	}
	const fechLocation = () => {
		if (ped_venda_cod) {
			getItemLocationOversight(ped_venda_cod)
		}
	}
	const fetchAttachments = () => {
		if (pl_ob_cod) {
			getItemAttachmentsConstructionPlan({
				id: pl_ob_cod,
				page: 1,
				paginate: true,
				created_at_from: apos_data_from,
				created_at_to: apos_data_to
			})
		}
	}
	const getPvsCods = (service) => {
		const { data } = service
		const cleanCods = new Set([...data.map(item => item.prog_serv_serv_cod).filter(prog_serv_serv_cod => prog_serv_serv_cod)])
		return Array.from(cleanCods).join(',')
	}
	const paginateMidias = async (page = 1) => {
		const pvsCods = getPvsCods(service)
		const respone = await getItemOversighttPhotos({
			id: pvsCods,
			page,
			paginate: true,
			ped_venda_date_from: apos_data_from,
			ped_venda_date_to: apos_data_to
		})
		if (respone?.data?.length) {
			const { data, ...rest } = respone
			const newItems = data.map(item => {
				const created_at = parseFromMysqlDateToJSDate(item.created_at)
				return {
					...item,
					timestamp: new Date(created_at).getTime()
				}
			})
			return setPhotos({
				items: newItems,
				...rest
			})
		}
		setPhotos({
			items: [],
			links: [],
		})
	}
	const fetchMidias = async () => {
		if (!service?.data?.length) {
			return setPhotos({
				items: [],
				links: [],
			})
		}
		paginateMidias(1)
	}
	const fetchMonitoringProgressWork = () => {
		if (pl_ob_cod) {
			getItemMonitoringProgressWork({
				id: pl_ob_cod,
				apos_data_from,
				apos_data_to
			})
		}
	}

	useEffect(() => {
		setBases64(null)
	}, [])
	useEffect(() => {
		fechLocation()
	}, [ped_venda_cod])
	useEffect(() => {
		fetchServices()
	}, [ped_venda_cod])
	useEffect(() => {
		fetchMonitoring()
	}, [ped_venda_cod, pl_ob_cod])
	useEffect(() => {
		fetchAttachments()
	}, [pl_ob_cod])
	useEffect(() => {
		const controllFecthItems = () => {
			if (tabgraphics) {
				return buttonChart?.current?.click()
			}
		}
		controllFecthItems()
	}, [
		tabservices,
		tabgraphics,
		tabtimeline,
		tabinfo,
		tabattachments,
		tabmidias,])
	useEffect(() => {
		const controllItems = async () => {
			if (service?.data && finanCialTotalWork) {
				const { data } = service
				let items = service
				if (config && filterPendente) {
					items = filter(data, 'pvs_status', config)
				}
				const dataShowInTable = await prepareDataToBeDisplayedInTable(items, whichPartToTake, finanCialTotalWork)
				setServices(dataShowInTable)
			}
		}
		controllItems()
	}, [service, config, filterPendente, whichPartToTake, finanCialTotalWork])
	useEffect(() => {
		const calcItems = async () => {
			if (service?.data?.length && finanCialTotalWork) {
				const { data } = service

				const {
					pl_ob_reais_prev,
					pl_ob_reais_exec,
					pl_ob_reais_miss,
					prog_serv_percen_progress,
					prog_serv_percen_acul,
					prog_serv_percen_acul_ant,
				} = generateSumItems(data, finanCialTotalWork)
				setSumItems({
					pl_ob_reais_prev: Math.abs(pl_ob_reais_prev),
					pl_ob_reais_exec: Math.abs(pl_ob_reais_exec),
					pl_ob_reais_miss: Math.abs(pl_ob_reais_miss),
					prog_serv_percen_progress: Math.abs(prog_serv_percen_progress),
					prog_serv_percen_acul: Math.abs(prog_serv_percen_acul),
					prog_serv_percen_acul_ant: Math.abs(prog_serv_percen_acul_ant),
				})
			}
		}
		calcItems()
	}, [service, finanCialTotalWork])
	useEffect(() => {
		const handleFormLocation = () => {
			if (Array.isArray(oversight_location?.data)) {
				const { data } = oversight_location
				if (data?.length) {
					const firstLocation = data[data?.length - 1]
					const {
						pv_loc_cod,
						pv_loc_pedidovenda,
						pv_loc_sentido,
						pv_loc_km_ini,
						pv_loc_km_init_mts,
						pv_loc_km_fim,
						pv_loc_km_fim_mts,
						pv_loc_obs,
						rod_sent_desc,
					} = firstLocation
					handleForm({
						pv_loc_obs,
						rod_sent_desc,
						pv_loc_cod,
						pv_loc_pedidovenda,
						pv_loc_sentido,
						rod_sent_cod: pv_loc_sentido,
						rod_km_ini: pv_loc_km_ini,
						rod_km_ini_mt: pv_loc_km_init_mts,
						rod_km_fim: pv_loc_km_fim,
						rod_km_fim_mt: pv_loc_km_fim_mts,
						pv_loc_km_ini,
						pv_loc_km_init_mts,
						pv_loc_km_fim,
						pv_loc_km_fim_mts,
					})
				}
			}
		}
		handleFormLocation()
	}, [oversight_location])
	useEffect(() => {
		const controllItems = () => {
			if (Array.isArray(attachments_contruction_plan?.data)) {
				const { data, ...rest } = attachments_contruction_plan
				const newItems = data.map(item => {
					const created_at = parseFromMysqlDateToJSDate(item.created_at)
					return {
						...item,
						timestamp: new Date(created_at).getTime()
					}
				})
				setAttchments({
					items: newItems,
					...rest
				})
			}
		}
		controllItems()
	}, [attachments_contruction_plan])
	useEffect(() => {
		fetchMidias()
	}, [service])
	useEffect(() => {
		fetchServicesMonitoring()
	}, [ped_venda_cod, apos_data_from, apos_data_to])
	useEffect(() => {
		fetchMonitoringProgressWork()
	}, [pl_ob_cod, apos_data_from, apos_data_to])
	useEffect(() => {
		const controllFecthItems = () => {
			if (viewMonitoring) {
				fetchServicesMonitoring()
			}
		}
		controllFecthItems()
	}, [viewMonitoring])
	useEffect(() => {
		const controllItems = () => {
			if (Array.isArray(monitoring_progressservice_work?.data)) {
				const { data } = monitoring_progressservice_work
				const dataWithCodigo = data.map(item => ({
					...item,
					gp_codigo: getPartTextFromCharacter({
						input: item.pvs_obs,
						joinString: '.',
						whichPartToTake,
					})
				}))
				const group = groupBy(dataWithCodigo, 'gp_codigo')
				setServicesMonitoring(group)
			}
		}
		controllItems()
	}, [monitoring_progressservice_work])
	useEffect(() => {
		const controllCheckBox = () => {
			if (servicesMonitoring) {
				return setDisableCheckBox(false)
			}
			setDisableCheckBox(true)
		}
		controllCheckBox()
	}, [servicesMonitoring])
	useEffect(() => {
		const controllItems = () => {
			if (service?.data) {
				const { data } = service
				const group = groupBy(data, 'pvs_servico')
				const datasets = []
				const labels = ['Disciplinas e seus progressos']
				Object.keys(group).sort((a, b) => a - b).forEach(item => {
					const data = group[item].filter(row => row.prog_serv_percen_progress > 0).map(row => row.prog_serv_percen_progress)
					const label = group[item][group[item]?.length - 1].serv_desc
					const backgroundColor = generateRandomColorPaletteWithBrightness(group[item]?.length)
					const chartData = {
						label: label,
						data: data,
						backgroundColor: backgroundColor.map(color => color.color)
					}
					if (data.length) {
						datasets.push(chartData)
					}
				})
				return setChartBarDisciplines({
					labels: labels,
					datasets,
				})
			}
		}
		controllItems()
	}, [service])
	useEffect(() => {
		const controllItems = () => {
			try {
				if (Array.isArray(monitoring_progress?.data) && Array.isArray(monitoring_progress_work?.data)) {
					const { data: executedProgress } = monitoring_progress_work
					const { data: tempPredictedProgress } = monitoring_progress
					if (!tempPredictedProgress?.length) {
						return
					}
					const groupPredictedProgress = groupBy(tempPredictedProgress, 'acob_pl_ob_cod')
					let predictedProgress = []
					const groups = Object.keys(groupPredictedProgress)
					for (let i = 0; i < groups.length; i++) {
						const nameGroup = groups[i];
						predictedProgress = groupPredictedProgress[nameGroup]
						if (i === 0) {
							break
						}
					}
					const data = createCombinedDataset({
						executedProgress: executedProgress,
						predictedProgress: predictedProgress
					})
					setChartLineAdvances(data)
				}
				return
			} catch (error) {
				console.log(error)
			}
		}
		controllItems()
	}, [monitoring_progress, monitoring_progress_work])
	useEffect(() => {
		const controllItems = () => {
			if (service?.data?.length) {
				const { data } = service
				const chartData = geneateDataChartPizzaPorCenExec({
					data, state: {
						sisf_sinc_int_amp_id: Array.isArray(sisf_sinc_int_amp_id) ? sisf_sinc_int_amp_id.join(',') : sisf_sinc_int_amp_id,
						pl_ob_cod: Array.isArray(pl_ob_cod) ? pl_ob_cod.join(',') : pl_ob_cod,
						conc_nome
					}
				})
				setChartPizzaPorCenExec(chartData)
			}
		}
		controllItems()
	}, [service])
	useEffect(() => {
		const readXlsCalcMemory = (rows) => {
			const clean_rows = cleanArray(rows)
			let row_exec_per = 0
			for (const clean_row of clean_rows) {
				for (const row of clean_row) {
					if (row === 'Acum. Atual') {
						row_exec_per = clean_row.find(item => typeof item === 'number')
						break
					}
				}
			}
			if (!row_exec_per) {
				return 0
			}
			return parseFloat(row_exec_per).toFixed(2)
		}
		const handleImportCalcMemorySpreedSheet = async ({ file }) => {
			const base64_ = await generateBase64(file)
			const xlsrows = await importExcelToArray(file)
			const current_prog_serv_qtd_acul = readXlsCalcMemory(xlsrows)
			handleForm({
				current_prog_serv_qtd_acul: current_prog_serv_qtd_acul,
				ane_pl_ob_path: base64_
			})
			setBases64(null)
		}
		const controllAttachments = () => {
			if (files?.length) {
				for (let i = 0; i < files.length; i++) {
					try {
						const item = files[i]
						if (!item.name) {
							continue
						}
						const fileExtension = item.name.split('.').pop().toLowerCase()
						if (allowedXlsExtensions.includes(fileExtension)) {
							return handleImportCalcMemorySpreedSheet({
								file: item,
								name: item.name,
							})
						}

					} catch (error) {
						console.log(error)
					}
				}
			}
		}
		controllAttachments()
	}, [files])
	const handleNewAttach = () => {
		handleClearAttach()
		if (buttonAttach.current?.click) {
			setIsPressed(!isPressed)
			setOpenModalAttach(openModal)
			return buttonAttach.current?.click()
		}
	}
	const handleMedias = () => {
		handleClearAttach()
		setIsPressed(!isPressed)
		setOpenModalMidia(openModal)
	}
	const handleClearAttach = () => {
		setBases64(null)
		setIsPressed(!isPressed)
		setOpenModalAttach(closeModal)
		setOpenModalMidia(closeModal)
	}
	const handleReport = () => {
		navigate(namesScreens.REPORT_CONSTRUCTION_PLAN)
	}
	const onChangeWhichPartToTake = ({ target: { value } }) => {
		if (value) {
			const qtdDots = String(value).split('.').length
			setWhichPartToTake(qtdDots)
			setPlaceHolderAgroupDisciplines(value)
		} else {
			loadConfig()
			setPlaceHolderAgroupDisciplines(defaultPlaceholderAgroupDisciplines)
		}
		storageCache(generateKeyCacheCustomizedWhichPartToTake(), { value })
	}
	const handlePageAttachments = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = attachments_contruction_plan
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return getItemAttachmentsConstructionPlan({
				id: pl_ob_cod,
				page: current_page - 1,
				paginate: true,
				created_at_from: apos_data_from,
				created_at_to: apos_data_to
			})
		}
		if (label === 'pagination.next') {
			return getItemAttachmentsConstructionPlan({
				id: pl_ob_cod,
				page: current_page < last_page ? current_page + 1 : 1,
				paginate: true,
				created_at_from: apos_data_from,
				created_at_to: apos_data_to
			})
		}
		if (!label) {
			return getItemAttachmentsConstructionPlan({
				id: pl_ob_cod,
				paga: 1,
				paginate: true,
				created_at_from: apos_data_from,
				created_at_to: apos_data_to
			})
		}
		return getItemAttachmentsConstructionPlan({
			id: pl_ob_cod,
			page: label,
			paginate: true,
			created_at_from: apos_data_from,
			created_at_to: apos_data_to
		})
	}
	const handlePageMidias = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = photos
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return paginateMidias(current_page - 1)
		}
		if (label === 'pagination.next') {
			return paginateMidias(current_page < last_page ? current_page + 1 : 1)
		}
		if (!label) {
			return paginateMidias(1)
		}
		return paginateMidias(label)
	}
	const refreshProgress = () => {
		fetchServices()
		fetchServicesMonitoring()
	}
	const refreshCharts = () => {
		fetchMonitoringProgressWork()
		fetchMonitoring()
	}
	return (
		<div className="content">
			<ContentHeader title={`Plano de Obra N° ${pl_ob_cod}`} />
			{reachedMilestone ? (
				<div className="card mb-3">
					<div className="card-body">
						<div className='row'>
							<div className='col-10'>
								<h5 className="card-title">Marco Atingido</h5>
							</div>
							<div clasName="col-2">
								<button className='btn btn-info btn-sm' onClick={() => setShowReachedMileStone(!showReachedMileStone)}>
									{!showReachedMileStone ? (
										(<i className='fa fa-eye'></i>)
									) : (<i className='fa fa-times-circle'></i>)}
								</button>
							</div>
						</div>
						{showReachedMileStone ? (
							<>

								<p className="card-text">
									{reachedMilestone}
								</p>
								<Link to="#" className="btn btn-info mr-2" onClick={postFixeReachedMileStone}>
									Gravar Marco
								</Link>
								<Link to="#" className="btn btn-dark" onClick={fecthReachedMilestone}>
									Verificar novamente
								</Link>
							</>
						) : null}
					</div>
				</div>
			) : null}
			<div className="row mb-3 mt-3">
				<div className='col-2'>
					<span className="badge badge-info">
						Acumulado Anterior {sumItems.prog_serv_percen_acul_ant > 0 ? Number(sumItems.prog_serv_percen_acul_ant).toFixed(2) : 0}%
					</span>
				</div>
				<div className='col-2'>
					<span className="badge badge-success">
						Progresso {sumItems.prog_serv_percen_progress > 0 ? Number(sumItems.prog_serv_percen_progress)?.toFixed(2) : 0}%
					</span>
				</div>
				<div className='col-2'>
					<span className="badge badge-warning">
						Acumulado {sumItems.prog_serv_percen_acul > 0 ? Number(sumItems.prog_serv_percen_acul).toFixed(2) : 0}%
					</span>
				</div>
				{Array.isArray(chartPizzaPorCenExec?.labels) ? <div className='col-2'>
					<span className="badge badge-secondary">
						A Executar {formatPercentage(100 - sumItems.prog_serv_percen_acul)}
					</span>
				</div> : null}
			</div>
			{Array.isArray(chartPizzaPorCenExec?.labels) ? <ProgressBar value={[
				{
					value: sumItems.prog_serv_percen_acul_ant,
				},
				{
					value: sumItems.prog_serv_percen_progress,
				},
				{
					value: 100 - sumItems.prog_serv_percen_acul
				}]} /> : ''}
			<div className="card mt-4 mb-4">
				<div className="card-body">
					<TabsConstructionPlan
						setservices={setservices}
						setgraphics={setgraphics}
						settimeline={settimeline}
						setinfo={setinfo}
						setattachments={setattachments}
						setmidias={setmidias}
						tabclassName={tabclassName}
					/>
					<Spinner direction={'center'} label={'  '} />
					<div className="bd-example bd-example-tabs mt-3">
						<div className="tab-content" id="myTabContent">
							<div className={`tab-pane fade ${tabservices}`} id="services" role="tabpanel" aria-labelledby="services-tab">
								<div className="row mb-3">
									<div className="col-md-3 col-sm-6">
										<button ref={buttonServices} className="btn btn-info btn-sm mb-3" onClick={refreshProgress}>Atualizar
											<i className="fa fa-retweet" aria-hidden="true"></i></button>
									</div>
									<div className="col-md-3 col-sm-6">
										<button className="btn btn-dark btn-sm mb-3" onClick={handleReport}>Relatório <i className="fa fa-bug" aria-hidden="true"></i></button>
									</div>
									<div className="col-md-3 col-sm-6">
										<div className="form-group">
											<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-info">
												<input type="checkbox" className="custom-control-input" id="viewMonitoring"
													checked={viewMonitoring} onChange={() => setViewMonitoring(!viewMonitoring)}
													disabled={disableCheckBox}
												/>
												<label className="custom-control-label" htmlFor="viewMonitoring">Ver histórico de avanços</label>
											</div>
										</div>
									</div>
									<div className="col-md-3 col-sm-12">
										<div className="form-group">
											<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-info">
												<input type="checkbox" className="custom-control-input" id="filterPendente"
													checked={filterPendente} onChange={() => setFilterPendente(!filterPendente)}
													disabled={disableCheckBox}
												/>
												<label className="custom-control-label" htmlFor="filterPendente">Ver somente as pendencias</label>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<label>Agrupar as disciplinas</label>
										<input className='form-control'
											placeholder={placeholderAgroupDisciplines}
											onBlur={onChangeWhichPartToTake} />
									</div>
									<div className='col-md-8 col-sm-12'>
										<FilterDateConstructionPlanProgressService
											fechItems={refreshProgress}
										/>
									</div>
								</div>
								{services && !viewMonitoring ? Object.keys(services).sort((a, b) => {
									const [a1, a2] = a.split('.').map(Number)
									const [b1, b2] = b.split('.').map(Number)
									return a1 - b1 || a2 - b2
								}).map(item => (
									<ListServiceConstructionPlan viewMonitoring={viewMonitoring} key={item} items={services[item]} />
								)) : null}
								{servicesMonitoring && viewMonitoring ? Object.keys(servicesMonitoring).sort((a, b) => {
									const [a1, a2] = a.split('.').map(Number)
									const [b1, b2] = b.split('.').map(Number);
									return a1 - b1 || a2 - b2
								}).map(item => (
									<ListServiceConstructionPlan viewMonitoring={viewMonitoring} key={item} items={servicesMonitoring[item]} />
								)) : null}
							</div>
							<div className={`tab-pane ${tabgraphics}`} id="graphics" role="tabpanel" aria-labelledby="graphics-tab">
								<div className='row'>
									<div className='col-md-2 col-sm-12'>
										<button ref={buttonChart} className="btn btn-info btn-sm mb-3" onClick={refreshCharts}>Atualizar
										</button>
									</div>
									<div className='col-md-10 col-sm-12'>
										<FilterDateConstructionPlanProgressService
											fechItems={refreshCharts} />
									</div>
								</div>
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Percentuais de Execução</h5>
									</div>
									<div className="card-body">
										{chartPizzaPorCenExec && (
											<ChartPie data={chartPizzaPorCenExec} />
										)}
									</div>
								</div>
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Progresso por Disciplina</h5>
									</div>
									<div className="card-body">
										{chartBarDisciplines && (
											<ChartBarDisciplines data={chartBarDisciplines} />
										)}
									</div>
								</div>
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Acumulado Previsto e Executado</h5>
									</div>
									<div className="card-body">
										{chartLineAdvances && (
											<LineChart data={chartLineAdvances} />
										)}
									</div>
								</div>
							</div>
							<div className={`tab-pane ${tabtimeline}`} id="timeline" role="tabpanel" aria-labelledby="timeline-tab">
								<button ref={buttonTimeline} className="btn btn-info btn-sm mb-3" onClick={fetchMonitoring}>Atualizar <Spinner direction={'center'} label={'  '} /> <i className="fa fa-retweet" aria-hidden="true"></i></button>
								<div className="card card-info">
									<div className="card-header">
										<h3 className="card-title">Cronograma da obra</h3>
										<div className="card-tools">
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="collapse"
											>
												<i className="fas fa-minus"></i>
											</button>
											<button
												type="button"
												className="btn btn-tool"
												data-toggle="modal"
												data-target="#concessionaria"
											>
												<i className="fa fa-question-circle"></i>
											</button>
										</div>
									</div>
									<div className="card-body">
										<FormMonitoring />
										{Array.isArray(monitoring_progress?.data) && (
											<MonitoringTable items={monitoring_progress?.data} />
										)}
									</div>
								</div>
							</div>
							<div className={`tab-pane ${tabinfo}`} id="info" role="tabpanel" aria-labelledby="info-tab">
								<div className="card card-info">
									<div className="card-header">
										<h3 className="card-title">Informações sobre a obra</h3>
										<div className="card-tools">
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="collapse"
											>
												<i className="fas fa-minus"></i>
											</button>
											<button
												type="button"
												className="btn btn-tool"
												data-toggle="modal"
												data-target="#concessionaria"
											>
												<i className="fa fa-question-circle"></i>
											</button>
										</div>
									</div>
									<div className="card-body">
										<FormContructions />
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-12">
										<div className="small-box bg-info">
											<div className="inner">
												<h3>{formatCurrency(sumItems.pl_ob_reais_prev)}</h3>
												<p>Valor total da obra</p>
											</div>
											<div className="icon">
												<i className="fa fa-building"></i>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										<div className="small-box bg-warning">
											<div className="inner">
												<h3>{formatCurrency(sumItems.pl_ob_reais_exec)}</h3>
												<p>Gastos com a obra até o momento</p>
											</div>
											<div className="icon">
												<i className="fa fa-building"></i>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										<div className="small-box bg-success">
											<div className="inner">
												<h3>{formatCurrency(sumItems.pl_ob_reais_miss)}</h3>
												<p>Valor disponível para uso</p>
											</div>
											<div className="icon">
												<i className="fa fa-building"></i>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className={`tab-pane ${tabattachments}`} id="attachments" role="tabpanel" aria-labelledby="attachments-tab">
								<div className="row">
									<div className="col-6">
										<button ref={buttonAttachs} className="btn btn-info btn-sm mb-3" onClick={fetchAttachments}>Atualizar <i className="fa fa-retweet" aria-hidden="true"></i></button>
									</div>
									<div className="col-6">
										<button className="btn btn-secondary btn-sm mb-3" onClick={handleNewAttach}>Adicionar anexo <i className="fa fa-paperclip" aria-hidden="true"></i>										</button>
									</div>
									<div className='col-12'>
										<FilterDateConstructionPlanProgressService
											fechItems={fetchAttachments} />
									</div>
								</div>
								{attachments?.items?.length && (
									<ListAttachmentsContructionPlan items={attachments?.items} />
								)}
								<div className="card-footer clearfix">
									<RenderLinkFooter
										handlePage={handlePageAttachments}
										links={attachments?.links}
									/>
								</div>
							</div>
							<div className={`tab-pane ${tabmidias}`} id="midias" role="tabpanel" aria-labelledby="midias-tab">
								<div className="row">
									<div className="col-6">
										<button ref={buttonMidias} className="btn btn-info btn-sm mb-3" onClick={fetchMidias}>
											Atualizar
											<i className="fa fa-retweet" aria-hidden="true"></i></button>
									</div>
									<div className="col-6">
										<button className="btn btn-sm btn-secondary" onClick={handleMedias} data-toggle="modal" data-target="#FormAttachMidias">Adicionar Foto ou Vídeo <i className="fa fa-paperclip" aria-hidden="true"></i></button>
									</div>
									<div className='col-12'>
										<FilterDateConstructionPlanProgressService />
									</div>
								</div>
								{photos?.items.length ? (
									<ListMidias items={photos?.items} fetchMidias={fetchMidias} />
								) : <p className='text-secondary'>Não há imagens para serem exibidas</p>}
								<div className="card-footer clearfix">
									<RenderLinkFooter
										handlePage={handlePageMidias}
										links={photos?.links}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button className="d-none" data-toggle="modal" ref={buttonAttach} data-target="#FormaAttachmentsConstructionPlan"></button>
				<div className={openModalAttach.className} style={openModalAttach.style} id="FormaAttachmentsConstructionPlan" tabIndex="-1" role="dialog" aria-labelledby="FormaAttachmentsConstructionPlanLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="FormaAttachmentsConstructionPlanLabel">
									Novo anexo
								</h5>
								<button ref={closeAttach} type="button" onClick={handleClearAttach} className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<FormaAttachmentsConstructionPlan closeModal={handleClearAttach} isPressed={isPressed} />
							</div>
						</div>
					</div>
				</div>
				<div className={openModalMidia.className} style={{
					...openModalMidia.style,
					maxHeight: '80%',
					overflowY: 'scroll'
				}} id="FormAttachMidias" tabIndex="-1" role="dialog" aria-labelledby="FormAttachMidiasLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="FormAttachMidiasLabel">
									Nova Foto ou Vídeo para o serviço {serv_sub_cat_desc}
								</h5>
								<button ref={closeMidias} type="button" onClick={handleClearAttach} className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<FormAttachMidias closeModal={handleClearAttach} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}
export default ViewContructionPlan