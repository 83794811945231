import React from "react"
import { Link } from "react-router-dom"
const RenderLinkFooter = ({ handlePage, links }) => {
    const RenderLink = ({ item }) => {
        return (
            <li className="page-item" key={item.label}>
                <Link
                    className={`page-link ${item.active ? 'text-light bg-info' : ''}`}
                    to="#"
                    onClick={() => handlePage(item)}
                >
                    {item.label === 'pagination.previous' ? (
                        <i className="fa fa-angle-left"></i>
                    ) : item.label === 'pagination.next' ? (
                        <i className="fa fa-angle-right"></i>
                    ) : (
                        item.label
                    )}
                </Link>
            </li>
        )
    }
    return (
        <ul className="pagination pagination-sm m-0 float-right">
            {Array.isArray(links) &&
                links.map((item) => (
                    <RenderLink item={item} key={item.label} handlePage={handlePage} />
                ))}
        </ul>
    )
}
export default RenderLinkFooter