import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import getLatLng from '../../../services/geolocation'
import { handleWaring } from '../../../services/isValid'
import { formatDate } from '../../../services/dateHandless'
import { closeModal, openModal } from '../../../constants'
import RenderActive from './renderActive'
import RenderKMLocation from './renderKMLocation'
import ModalShowCheckInspection from '../../modal/modalShowCheckInspection'
import './style.css'
const ListOversight = ({
	data,
	selectedItems,
	setSelectedItems,
	handleOpenOversights,
	selectedNot,
	handleNotify

}) => {
	const { handleForm,
		state: {
			type_date,
			serv_cat_prazo_from,
			serv_cat_prazo_to
		} } = useContext(formContext)
	const {
		getAllItemCheckInspection,
		deleteItemOversight,
		setInfoRequest,
		isConection,
	} = useContext(requestContext)
	const [items, setItems] = useState([])
	const [openModalFisc, setOpenModalFisc] = useState(closeModal)
	useEffect(() => {
		const prepareDataToList = () => {
			if (!data?.data?.length) {
				return setItems([])
			}
			const { data: dataFromApi } = data
			setItems(dataFromApi)
		}
		prepareDataToList()
	}, [data])
	const { user } = useContext(authContext)
	const handleDelete = async () => {

		const confirm = window.confirm(
			`Deseja mesmo cancelar a(s) ocorrência(s) selecionada(s)?`
		)
		if (!confirm) {
			return
		}
		if (confirm) {
			const fisc_obs = window.prompt(
				`Explique o porque você está cancelando essa(s) ocorrência(s)`
			)
			if (!fisc_obs) {
				return
			}
			if (fisc_obs.length > 500) {
				return setInfoRequest(
					handleWaring('Você não pode colocar mais do que 500 caracteres')
				)
			}
			if (fisc_obs.length < 5) {
				return setInfoRequest(
					handleWaring('Você precisa colocar mais do que 5 caracteres')
				)
			}
			const postion = isConection
				? isConection
					? await getLatLng()
					: { lat: 0, lng: 0 }
				: { lat: 0, lng: 0 }


			const toSend = items.filter(obj => selectedItems.includes(obj.ped_venda_cod))

			toSend.forEach(item => {
				deleteItemOversight({
					id: item.ped_venda_cod,
					fisc_obs,
					pv_servico: item.pv_servico,
					fisc_lat: postion.lat,
					fisc_lng: postion.lng,
					ped_venda_date_from: serv_cat_prazo_from,
					ped_venda_date_to: serv_cat_prazo_to,
					type_date: type_date
				})

			});

		}
	}

	const checked = ({
		ped_venda_cod
	}) => {
		if (!selectedItems.length) {
			return false
		}
		return selectedItems?.includes(ped_venda_cod)
	}
	const onChangeCheckBox = ({ target: { value } }) => {
		if (selectedItems.length) {
			if (selectedItems.find(i => i === Number(value))) {
				return setSelectedItems(selectedItems.filter(i => i !== Number(value)))
			}
			return setSelectedItems([...selectedItems, Number(value)])
		}
		return setSelectedItems([Number(value)])
	}

	const handleOpenModalFisc = (item) => {
		handleForm({
			...item,
		})
		getAllItemCheckInspection(item.ped_venda_cod)
		setOpenModalFisc(openModal)
	}
	const handleCloseModal = () => {
		handleForm({
			conc_nome: '',
			lot_conc_desc: '',
			ped_venda_cod: '',
			ped_venda_date: '',
			ped_venda_status: '',
			ped_venda_concessionaria: '',
			ped_venda_responsavel: '',
			ped_venda_rodovia: '',
			last_modified_ped_venda: '',
			st_desc: '',
			st_cor_valor: '',
			name: '',
			rod_desc: '',
			rod_km: '',
			pvs_prazo_fim: '',
			tip_ped_venda_desc: '',
			sisf_sinc_id: '',
			gp_desc: '',
			sinc_st_desc: '',
			serv_desc: '',
			serv_cat_desc: '',
			serv_sub_cat_desc: '',
			pvs_obs: '',
			pv_loc_pedidovenda: '',
			pv_loc_sentido: '',
			pv_loc_km_ini: '',
			pv_loc_km_init_mts: '',
			pv_loc_km_fim: '',
			pv_loc_km_fim_mts: '',
			pv_loc_obs: '',
			pv_loc_lat: '',
			pv_loc_lng: '',
			rod_sent_desc: '',
		})
		setOpenModalFisc(closeModal)
	}
	if (!items.length) {
		return (
			<div className='col-12'>
				Não há itens para serem exibidos
			</div>
		)
	}

	return (
		<div className='col-12 cardBg'>
			{items.map(item => (
				<div className='col-12 p-3 mb-1 customBorder' key={item.ped_venda_cod} style={{ '--color-var': item.st_cor_valor }}>
					<div className='row justify-content-between'>
						<div className='col-sm-12 col-md-6  mb-2 ncTitle' style={{ color: item.st_cor_valor }}>
							<input
								type='checkbox'
								value={item.ped_venda_cod}
								id='flexCheckChecked'
								checked={checked({ ped_venda_cod: item.ped_venda_cod })}
								onChange={onChangeCheckBox} />
							&nbsp;
							# {item?.sisf_sinc_id || item.ped_venda_cod} ({item.st_desc})
						</div>

						{item.sinc_st_desc && (
							<div className='col-6 col-md-3  mb-2'>
								<div className='badge text-light bg-dark text-center p-2'											>
									{item.sinc_st_desc} no SISF
								</div>
							</div>
						)}

						{item.tip_ped_venda_desc && (
							<div className='col-6 col-md-3 mb-2 d-flex justify-content-end'>
								<div
									className='badge badgeMax text-light bg-secondary text-center p-2'
								>
									{item.tip_ped_venda_desc}
								</div>
							</div>
						)}

					</div>

					<div className='row'>

						<div className='col-12 col-md-4 mb-2'>
							<span className='text-secondary mr-2'>
								Concessionária:
							</span>
							{item.conc_nome}
						</div>
						<div className='col-12 col-md-4 mb-2'>
							<span className='text-secondary mr-2'>
								Lote:
							</span>
							{item.lot_conc_desc}
						</div>
						{item.gp_desc && (
							<div className='col-12 col-md-4 mb-2'>
								Grupo {item.gp_desc}
							</div>
						)}
					</div>

					<div className='row'>
						<RenderActive item={item} />
						{item.rod_desc && (
							<div className='col-md-4 col-sm-12 mb-3'>
								<span className='text-secondary'>Rodovia:</span> {item?.rod_km} - {item.rod_desc}
							</div>
						)}
						{item.rod_desc && (
							<div className='col-md-4 col-sm-12 mb-3'>
								<span className='text-secondary'>Sentido:</span> {item.rod_sent_desc}
							</div>
						)}
						{(
							item.pv_loc_km_ini !== null &&
							item.pv_loc_km_init_mts !== null &&
							item.pv_loc_km_fim !== null &&
							item.pv_loc_km_fim_mts !== null) ? (
							<RenderKMLocation item={item} />
						) : null}

						<div className='col-md-3 col-sm-12 mb-3'>
							<span className='text-secondary'>Responsável:</span> {item.name}
							<Link className='text-secondary ml-2' onClick={() => handleOpenModalFisc(item)} to={'#'}>
								(Ver Fiscalizações)
							</Link>
						</div>
						<div className='col-md-3 col-sm-12 mb-2'>
							<span className='text-secondary mr-2'>
								Constatação:
							</span>
							{formatDate(item.ped_venda_date)}
						</div>
						<div className='col-md-3 col-sm-12 mb-2 '>
							<span className='text-secondary mr-2'>
								Prazo:
							</span>
							{formatDate(item.pvs_prazo_fim)}
						</div>
						<div className='col-md-3 col-sm-12 mb-2 '>
							<span className='text-secondary mr-2'>
								Último Retorno:
							</span>
							{formatDate(item.last_modified_ped_venda)}
						</div>
					</div>
				</div>
			))}
			{selectedItems.length ? (
				<div className="floating-button d-none d-sm-block">
					<button type="button" className="btn btn-info mr-1"
						onClick={handleOpenOversights}>
						<i className="fa fa-chevron-right"></i>
						&nbsp; Visualizar ({selectedItems.length})
					</button>
					{selectedNot.length && <button type="button" className="btn btn-warning mr-1"
						onClick={handleNotify}>
						<i className="fa fa-chevron-right"></i>
						&nbsp; Notificar ({selectedItems.length})
					</button>}
					{user?.set_nivel === 0 && (<button type="button" className="btn btn-danger mr-1"
						onClick={handleDelete}>
						<i className="fa fa-chevron-right"></i>
						&nbsp; Apagar ({selectedItems.length})
					</button>
					)}
				</div>
			) : <div className="floating-button">
				<button disabled type="button" className="btn btn-info">
					<i className="fa fa-chevron-right"></i>
				</button>
			</div>}
			<ModalShowCheckInspection handleCloseModal={handleCloseModal} openModalFisc={openModalFisc} />
		</div>
	)
}
export default ListOversight
