const RenderHeaderModal = ({
    serv_desc,
    pvs_obs,
    serv_sub_cat_desc,
    prog_serv_cod
}) => {
    return (
        <h5 className="modal-title" id={`ServiceAdvancementLabel${prog_serv_cod}`}>
            {serv_desc} | ({pvs_obs}) {serv_sub_cat_desc}
        </h5>
    )
}
export default RenderHeaderModal