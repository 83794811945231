import { namesCache } from '../../constants'
import { storageCache } from '../cacheManager'

const getPosition = () => {
	// Simple wrapper
	return new Promise((res, rej) => {
		navigator.geolocation.getCurrentPosition(res, rej)
	})
}
const getLatLng = async () => {
	try {
		const position = await getPosition()  // wait for getPosition to complete
		return {
			lat: position.coords.latitude,
			lng: position.coords.longitude,
		}
	} catch (error) {
		storageCache(namesCache.GEOCODING, error)
		return {
			lat: -23.45620955586194,
			lng: -46.641376207144965
		}
	}
}
export default getLatLng
