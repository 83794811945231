import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring } from '../../../services/isValid'
import { allowedImageExtensions, allowedMoviesExtensions } from '../../../constants'
import { generateBase64, getExt } from '../../../services/files'
import { generateDateTimeStampJS } from '../../../services/dateHandless'
import { getUniqueRandomInt } from '../../../services/randomId'
import ListMidias from '../../list/midias'
import getLatLng from '../../../services/geolocation'
import { loadConfigWhichPartToTake } from '../../../services/constructionPlan'
import { getPartTextFromCharacter } from '../../../services/stringHandless'
import { groupBy } from '../../../services/arrayHandless'
import FormSelectDiscipline from '../selectDiscipline'

const FormAttachMidias = ({ closeModal }) => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { service, setInfoRequest, postItemOversightPhotos } = useContext(requestContext)
	const [serviceItems, setServiceItems] = useState([])
	const [servicesSubCat, setServicesSubCat] = useState([])
	const [services, setServices] = useState([])
	const [whichPartToTake, setWhichPartToTake] = useState(2)
	useEffect(() => {
		const loadConfig = async () => {
			const value = await loadConfigWhichPartToTake()
			if (!value) {
				return
			}
			setWhichPartToTake(value)
		}
		loadConfig()
	}, [])
	const input = useRef(null)
	const {
		pvs_loc_foto_legenda,
		pvs_loc_foto_pedidovenda,
	} = state
	const [files, setFiles] = useState([])
	useEffect(() => {
		const controllItems = () => {
			try {
				if (Array.isArray(service?.data)) {
					const { data } = service
					const itemsWithGpCod = data.map(item => ({
						serv_desc: item.serv_desc,
						pvs_servico: item.pvs_servico,
						prog_serv_obra: item.prog_serv_obra,
						gp_cod: getPartTextFromCharacter({
							input: item.serv_sub_cat_subitem_der,
							joinString: '.',
							whichPartToTake,
						})
					}))
					const groupedItems = groupBy(itemsWithGpCod, 'gp_cod')
					const itemsFromServices = []
					Object.keys(groupedItems).forEach(item => {
						itemsFromServices.push(groupedItems[item][0])
					})
					setServices(itemsFromServices.sort((a, b) => {
						const [a1, a2] = a.gp_cod.split('.').map(Number)
						const [b1, b2] = b.gp_cod.split('.').map(Number)
						return a1 - b1 || a2 - b2
					}))
					const serviceSubcatGPSorted = data.sort((a, b) => {
						const [a1, a2] = a.serv_sub_cat_subitem_der.split('.').map(Number)
						const [b1, b2] = b.serv_sub_cat_subitem_der.split('.').map(Number)
						return a1 - b1 || a2 - b2
					}).map(item => ({
						...item,
						gp_cod: getPartTextFromCharacter({
							input: item.serv_sub_cat_subitem_der,
							joinString: '.',
							whichPartToTake,
						})
					}))
					setServicesSubCat(serviceSubcatGPSorted)
					setServiceItems(serviceSubcatGPSorted)
				}
			} catch (error) {
				console.log('error', error)
			}
		}
		controllItems()
	}, [service])
	const handleMedia = () => {
		if (input?.current?.click) {
			return input?.current?.click()
		}
	}
	const onChangeFile = async ({ target: { files: filesFromInput } }) => {
		if (!filesFromInput?.length) {
			return setInfoRequest(handleWaring('Você precisa selecionar pelo menos um arquivo'))
		}
		const resultFiles = []
		const { lat: pvs_loc_foto_lat, lng: pvs_loc_foto_lng } = await getLatLng()
		for (const file of filesFromInput) {
			const { name } = file
			const ext = getExt(name)
			if (allowedImageExtensions.includes(ext) || allowedMoviesExtensions.includes(ext)) {
				const base64 = await generateBase64(file)
				resultFiles.push({
					timestamp: generateDateTimeStampJS(),
					pvs_loc_foto_cod: getUniqueRandomInt(),
					pvs_loc_foto_path: base64,
					pvs_loc_foto_legenda: pvs_loc_foto_legenda,
					pvs_loc_foto_pedidovenda,
					pvs_loc_foto_lat,
					pvs_loc_foto_lng,
				})
			}
		}
		if (!resultFiles.length) {
			return setInfoRequest(handleWaring('Você precisa selecionar pelo menos um arquivo'))
		}
		if (files?.length) {
			return setFiles([...files, resultFiles])
		}
		setFiles(resultFiles)
	}
	const handleSendMedia = () => {
		if (!files.length || !pvs_loc_foto_pedidovenda || !pvs_loc_foto_legenda) {
			return setInfoRequest(handleWaring('Você precisa preencher todos os campos obrigatórios'))
		}
		files.forEach(item => {
			postItemOversightPhotos(item)
		})
		if (closeModal) {
			closeModal()
		}
	}
	const filterServiceSubCat = ({
		target: { value }
	}) => {
		if (!value || !serviceItems?.length) {
			return
		}
		const filteredData = serviceItems.filter(item => item.gp_cod === value)
		setServicesSubCat([])
		handleForm({
			ane_pl_ob_pvs_cod: '',
		})
		setServicesSubCat(filteredData)
	}
	return (
		<div className="row">
			<div className="form-group col-12">
				<label>Observação sobre a foto ou vídeo:</label>
				<textarea type="text"
					className={`form-control ${!pvs_loc_foto_legenda && 'is-invalid'}`}
					onChange={onChange}
					name='pvs_loc_foto_legenda' value={pvs_loc_foto_legenda}
					placeholder='Descreva brevemente sobre o anexo' />
			</div>
			<FormSelectDiscipline
				filterServiceSubCat={filterServiceSubCat}
				services={services}
				servicesSubCat={servicesSubCat}
			/>
			<input className="d-none"
				type="file"
				multiple
				ref={input}
				onChange={onChangeFile}
			/>
			<div className="col-6">
				<button className="btn btn-info" onClick={handleSendMedia}>
					Enviar  <i className="fa fa-paper-plane" aria-hidden="true"></i>
				</button>
			</div>
			<div className="col-6">
				<button className="btn btn-secondary" onClick={handleMedia}>
					Anexar <i className="fa fa-paperclip" aria-hidden="true"></i>
				</button>
			</div>
			<div className="col-12">
				{files.length ? (
					<ListMidias items={files} setFiles={setFiles} />
				) : ''}
			</div>
		</div>
	)
}
export default FormAttachMidias