import React, { useContext, useEffect, useRef, useState } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { formContext } from '../../../context/formContext'
import TabsConstructionPlan from '../../../components/tabs/constructionPlan'
import { formatCurrency } from '../../../services/stringHandless'
import FormMonitoring from '../../../components/form/constructionPlan/monitoring'
import FormContructions from '../../../components/form/constructionPlan'
import FormMonitoringProgressConstructionPlan from '../../../components/form/monitoringProgressConstructionPlan'
import MonitoringTable from '../../../components/table/constructionPlan/monitoring'
import { requestContext } from '../../../context/requestContext'
import ListServiceConstructionPlan from '../../../components/table/serviceConstructionPlan'
import { Link } from 'react-router-dom'
import Spinner from '../../../components/spinner'
import { allowedXlsExtensions } from '../../../constants'
import { handleError, handleWaring, isValid } from '../../../services/isValid'
import { generateBase64, getExt } from '../../../services/files'
import { readSpreedSheetOfNewConstructionPlan } from '../../../services/spreedsheet'
import { generateSumItems, prepareDataToBeDisplayedInTable } from '../../../services/constructionPlan'
import { ProgressBar } from '../../../components/progressBar'
import getLatLng from '../../../services/geolocation'
import { filterAndRemove } from '../../../services/arrayHandless'
const NewContructionPlan = () => {
	const tabclassName = 'fade active show'
	const [tabservices, setservices] = useState(null)
	const [tabgraphics, setgraphics] = useState(null)
	const [tabtimeline, settimeline] = useState(null)
	const [tabinfo, setinfo] = useState(tabclassName)
	const [tabattachments, setattachments] = useState(null)
	const [tabmidias, setmidias] = useState(null)
	const [services, setServices] = useState([])
	const inputFile = useRef(null)
	const [progressReadSpreedSheet, setProgressReadSpreedSheet] = useState(0)
	const progressBarRef = useRef(null)
	useEffect(() => {
		const controllVisibleProgressBarReadSpreedSheet = () => {
			try {
				if (progressBarRef?.current?.style?.display && (progressReadSpreedSheet > 99)) {
					setInterval(() => {
						progressBarRef.current.style.display = 'none'
					}, 3000)
				}
			} catch (error) {
				console.log(error)
			}
		}
		controllVisibleProgressBarReadSpreedSheet()
	}, [progressBarRef, progressReadSpreedSheet])
	const [sumItems, setSumItems] = useState({
		pl_ob_reais_prev: 0,
		pl_ob_reais_exec: 0,
		pl_ob_reais_miss: 0,
		prog_serv_percen_progress: 0,
		prog_serv_percen_acul: 0,
		prog_serv_percen_acul_ant: 0,
	})
	useEffect(() => {
		const calcItems = () => {
			if (typeof services === 'object') {
				const items = []
				Object.keys(services).forEach(key => {
					items.push(...services[key])
				})
				const {
					pl_ob_reais_prev,
					pl_ob_reais_exec,
					pl_ob_reais_miss,
					prog_serv_percen_progress,
					prog_serv_percen_acul,
					prog_serv_percen_acul_ant,
				} = generateSumItems(items)
				setSumItems({
					pl_ob_reais_prev: Math.abs(pl_ob_reais_prev),
					pl_ob_reais_exec: Math.abs(pl_ob_reais_exec),
					pl_ob_reais_miss: Math.abs(pl_ob_reais_miss),
					prog_serv_percen_progress: Math.abs(prog_serv_percen_progress),
					prog_serv_percen_acul: Math.abs(prog_serv_percen_acul),
					prog_serv_percen_acul_ant: Math.abs(prog_serv_percen_acul_ant),
				})
			}
		}
		calcItems()
	}, [services])
	const [monitoring_progress, setMonitoringProgress] = useState([])
	const { state: {
		conc_nome,
		pl_ob_tipo,
		tip_ob_desc,
		pl_ob_data_program_ini,
		pl_ob_data_program_fim,
		pl_ob_data_ana_ini,
		pl_ob_data_ana_fim,
		pl_ob_data_prev_ini,
		pl_ob_data_prev_fim,
		pl_ob_cod_item,
		pl_ob_desc_object,
		pl_ob_spreadsheet,
		rod_cod,
		pv_loc_sentido,
		pv_loc_km_ini,
		pv_loc_km_init_mts,
		pv_loc_km_fim,
		pv_loc_km_fim_mts,
		pv_loc_obs,
		pv_loc_lat,
		pv_loc_lng,
		rod_sent_cod,
		rod_km_ini,
		rod_km_ini_mt,
		rod_km_fim_mt,
		rod_km_fim,
	},
		handleForm } = useContext(formContext)
	const {
		postItemConstructionPlan
	} = useContext(requestContext)
	useEffect(() => {
		const prepareDataPlObLocal = async () => {
			const { lat, lng } = await getLatLng()
			handleForm({
				pv_loc_sentido: rod_sent_cod,
				pv_loc_km_ini: rod_km_ini,
				pv_loc_km_init_mts: rod_km_ini_mt,
				pv_loc_km_fim: rod_km_fim,
				pv_loc_km_fim_mts: rod_km_fim_mt,
				pv_loc_obs,
				pv_loc_lat: lat,
				pv_loc_lng: lng,
			})
		}
		prepareDataPlObLocal()
	}, [
		rod_sent_cod,
		rod_km_ini,
		rod_km_ini_mt,
		rod_km_fim_mt,
		rod_km_fim
	])
	const {
		setInfoRequest,
		getToDownloadDefaultSpreedSheetConstructionPlan
	} = useContext(requestContext)
	const handleDownloadSpreedSheet = () => {
		getToDownloadDefaultSpreedSheetConstructionPlan(pl_ob_tipo)
	}
	const handleOpenFileExplorer = () => {
		if (inputFile.current?.click) {
			inputFile.current.click()
		}
	}
	const onChangeFile = async ({ target }) => {
		try {
			const { files } = target
			if (files?.length > 1) {
				return setInfoRequest(handleWaring('Você só pode enviar um arquivo por vez'))
			}
			const file = files[0]
			const pl_ob_spreadsheet = await generateBase64(file)
			const { name } = file
			const ext = getExt(name)
			if (!allowedXlsExtensions.includes(ext)) {
				return setInfoRequest(handleError(`Você deve selecionar uma planilha válida, o tipo de planilha aceitos são: ${allowedXlsExtensions.join(',')}`))
			}
			const {
				services,
				pl_ob_desc_objet,
			} = await readSpreedSheetOfNewConstructionPlan({
				file,
				setProgress: setProgressReadSpreedSheet
			})
			handleForm({
				pl_ob_desc_objet,
				pl_ob_spreadsheet
			})
			if (services.length) {
				const items = await prepareDataToBeDisplayedInTable({ data: services })
				setServices(items)
			}
		} catch (error) {
			setInfoRequest(handleError(String(error).replace('Error: ', '')))
		}
	}
	const handleSubmit = () => {
		const ob_local = {
			pv_loc_sentido,
			pv_loc_km_ini,
			pv_loc_km_init_mts,
			pv_loc_km_fim,
			pv_loc_km_fim_mts,
			pv_loc_obs,
			pv_loc_lat,
			pv_loc_lng,
		}
		const valid = isValid(ob_local)
		if (!valid) {
			return setInfoRequest(handleWaring('Você precisa informar o local (KM Inicial, KM Final) aonde a obra será realizado'))
		}
		const ob_services = []
		Object.keys(services).forEach(key => {
			ob_services.push(...services[key].map(service => {
				delete service.gp_codigo
				delete service.prog_serv_obra
				delete service.st_cor_valor
				delete service.st_desc
				delete service.prog_serv_qtd_acul
				delete service.prog_serv_qtd_acul_ant
				delete service.prog_serv_percen_progress
				delete service.prog_serv_percen_acul_ant
				delete service.prog_serv_percen_acul
				delete service.pvs_valor_total
				return { ...service }
			}))
		});
		let iscorrectlyFilledIn_ = true
		for (const ob_service of ob_services) {
			const { iscorrectlyFilledIn, serv_desc, serv_sub_cat_desc } = ob_service
			iscorrectlyFilledIn_ = iscorrectlyFilledIn
			if (!iscorrectlyFilledIn) {
				setInfoRequest(handleError(`O Serviço ${serv_sub_cat_desc} da Disciplina ${serv_desc} foi preenchido incorretamente`))
				break
			}
		}
		if (!iscorrectlyFilledIn_) {
			return
		}
		const payload = {
			pl_ob_data_program_ini,
			pl_ob_data_program_fim,
			pl_ob_data_ana_ini,
			pl_ob_data_ana_fim,
			pl_ob_data_prev_ini,
			pl_ob_data_prev_fim,
			pl_ob_valor: sumItems.pl_ob_reais_prev,
			pl_ob_cod_item,
			pl_ob_desc_object,
			pl_ob_spreadsheet,
			rod_cod,
			ob_services,
			ob_local,
			acompanhamento_obra: monitoring_progress,
			pl_ob_tipo
		}
		const validPayload = isValid(payload)
		if (!validPayload) {
			return setInfoRequest(handleWaring('Você precisa preencher todos os campos desse formulário'))
		}
		postItemConstructionPlan({
			...payload
		})
	}
	const handleDeleteMonitoring = (id) => {
		if (!monitoring_progress.length) {
			return setMonitoringProgress([])
		}
		const { data } = filterAndRemove(monitoring_progress, 'id', id)
		setMonitoringProgress(data)
	}
	return (
		<div className="content">
			<ContentHeader title={`Plano de Obra para ${conc_nome}`} />
			<div className="card mt-4 mb-4">
				<div className="card-body">
					<TabsConstructionPlan
						setservices={setservices}
						setgraphics={setgraphics}
						settimeline={settimeline}
						setinfo={setinfo}
						setattachments={setattachments}
						setmidias={setmidias}
						tabclassName={tabclassName}
					/>
					<div className="bd-example bd-example-tabs mt-3">
						<div className="tab-content" id="myTabContent">
							<div className={`tab-pane fade ${tabservices}`} id="services" role="tabpanel" aria-labelledby="services-tab">
								{tip_ob_desc ? (
									<p>
										<Link to={'#'} onClick={handleDownloadSpreedSheet} className='text-secondary'>
											Baixar planilha modelo do tipo de obra {tip_ob_desc} <Spinner direction={'center'} label={'Buscando pela planilha'} />
										</Link>
									</p>
								) : null}
								{tip_ob_desc ? (
									<Link to={'#'} onClick={handleOpenFileExplorer} className='text-secondary'>
										Importar a planilha modelo do tipo de obra {tip_ob_desc}
									</Link>
								) : null}
								<div ref={progressBarRef}>
									<ProgressBar
										value={progressReadSpreedSheet}
										label={`Progresso da Importação ${progressReadSpreedSheet}`} />
								</div>
								{services ? Object.keys(services).sort((a, b) => {
									const [a1, a2] = a.split('.').map(Number)
									const [b1, b2] = b.split('.').map(Number)
									return a1 - b1 || a2 - b2
								}).map(item => (
									<ListServiceConstructionPlan key={item} items={services[item]} />
								)) : null}
								<input
									ref={inputFile}
									className='d-none'
									type='file'
									accept={allowedXlsExtensions.join(',')}
									onChange={onChangeFile} />
							</div>
							<div className={`tab-pane ${tabgraphics}`} id="graphics" role="tabpanel" aria-labelledby="graphics-tab">
								<p className='text-secondary'>Os gráficos apareceram aqui quando houver avanços na obra</p>
							</div>
							<div className={`tab-pane ${tabtimeline}`} id="timeline" role="tabpanel" aria-labelledby="timeline-tab">
								<div className="card card-info">
									<div className="card-header">
										<h3 className="card-title">Cronograma da obra</h3>
										<div className="card-tools">
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="collapse"
											>
												<i className="fas fa-minus"></i>
											</button>
											<button
												type="button"
												className="btn btn-tool"
												data-toggle="modal"
												data-target="#concessionaria"
											>
												<i className="fa fa-question-circle"></i>
											</button>
										</div>
									</div>
									<div className="card-body">
										<FormMonitoring />
									</div>
								</div>
								<div className="card card-info">
									<div className="card-header">
										<h3 className="card-title">Cronograma do Previsto a ser executado</h3>
										<div className="card-tools">
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="collapse"
											>
												<i className="fas fa-minus"></i>
											</button>
											<button
												type="button"
												className="btn btn-tool"
												data-toggle="modal"
												data-target="#concessionaria"
											>
												<i className="fa fa-question-circle"></i>
											</button>
										</div>
									</div>
									<div className="card-body">
										<FormMonitoringProgressConstructionPlan
											monitoringProgressItems={monitoring_progress}
											setMonitoringProgressItems={setMonitoringProgress}
										/>
										{monitoring_progress.length ? (
											<MonitoringTable isNew items={monitoring_progress} handleDelete={handleDeleteMonitoring} />
										) : null}
									</div>
								</div>
							</div>
							<div className={`tab-pane ${tabinfo}`} id="info" role="tabpanel" aria-labelledby="info-tab">
								<div className="card card-info">
									<div className="card-header">
										<h3 className="card-title">Informações sobre a obra</h3>
										<div className="card-tools">
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="collapse"
											>
												<i className="fas fa-minus"></i>
											</button>
											<button
												type="button"
												className="btn btn-tool"
												data-toggle="modal"
												data-target="#concessionaria"
											>
												<i className="fa fa-question-circle"></i>
											</button>
										</div>
									</div>
									<div className="card-body">
										<FormContructions />
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-12">
										<div className="small-box bg-info">
											<div className="inner">
												<h3>{formatCurrency(sumItems.pl_ob_reais_prev)}</h3>
												<p>Valor total da obra</p>
											</div>
											<div className="icon">
												<i className="fa fa-building"></i>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										<div className="small-box bg-warning">
											<div className="inner">
												<h3>{formatCurrency(sumItems.pl_ob_reais_exec)}</h3>
												<p>Gastos com a obra até o momento</p>
											</div>
											<div className="icon">
												<i className="fa fa-building"></i>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										<div className="small-box bg-success">
											<div className="inner">
												<h3>{formatCurrency(sumItems.pl_ob_reais_miss)}</h3>
												<p>Valor disponível para uso</p>
											</div>
											<div className="icon">
												<i className="fa fa-building"></i>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className={`tab-pane ${tabattachments}`} id="attachments" role="tabpanel" aria-labelledby="attachments-tab">
								<p className='text-secondary'>Não há anexos para serem exibidas</p>
							</div>
							<div className={`tab-pane ${tabmidias}`} id="midias" role="tabpanel" aria-labelledby="midias-tab">
								<p className='text-secondary'>Não há imagens para serem exibidas</p>
							</div>
						</div>
					</div>
					<button className='btn btn-info mt-3' onClick={handleSubmit}>
						Salvar
					</button>
					<Spinner direction={'center'} label={'Salvando...'} />
				</div>
			</div>
		</div>
	)
}
export default NewContructionPlan