import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../../context/formContext'
import Dealership from '../../dealership'
import SelectHighway from '../../selectHighway'
import { LoadMaxDate } from '../../../../services/dateHandless'
const FilterConstructionPlan = () => {
	const { state, onChange, handleForm } = useContext(formContext)
	const {
		ped_venda_date_init,
		ped_venda_date_fim,
		type_date,
		see_completed_works
	} = state
	useEffect(() => {
		handleForm({
			type_date: 8,
		})
	}, [])
	const types_date = [
		{ desc: 'Não filtrar por data', value: 8 },
		{ desc: 'Data programada inciar', value: 0 },
		{ desc: 'Data programada terminar', value: 1 },
		{ desc: 'Data execução iniciar', value: 2 },
		{ desc: 'Data execução terminar', value: 3 },
		{ desc: 'Data análise iniciar', value: 4 },
		{ desc: 'Data análise terminar', value: 5 },
		{ desc: 'Data prevista iniciar', value: 6 },
		{ desc: 'Data prevista terminar', value: 7 },
	]
	const [maxDate, setMaxDate] = useState(null)
	useEffect(() => {
		LoadMaxDate(setMaxDate)
	}, [])
	return (
		<div className="row">
			<div className='col-12 mb-3 text-secondary'>Filtrar por Concessionárias e Rodovias</div>
			<div className="col-md-6 col-sm-12">
				<Dealership />
			</div>
			<div className="col-md-6 col-sm-12  mb-3">
				<SelectHighway />
			</div>
			<div className="col-12 mb-3 text-secondary">Filtrar por Cronograma</div>
			<div className='form-group col-md-4 col-sm-12'>
				<label>Filtrar por qual data:</label>
				<select
					name='type_date'
					onChange={onChange}
					className={`form-control ${type_date === null && 'is-invalid'}`}>
					{types_date.map(item => (
						<option key={item.value} value={item.value}>
							{item.desc}
						</option>
					))}
				</select>
			</div>
			{type_date <= 7 && (
				<>
					<div className="form-group col-md-4 col-sm-6">
						<label>Da data:</label>
						<div className="input-group">
							<input type="date"
								onChange={onChange}
								name='ped_venda_date_init' value={ped_venda_date_init}
								max={ped_venda_date_fim}
								className={`form-control ${!ped_venda_date_init && 'is-invalid'}`}
							/>
						</div>
					</div>
					<div className="form-group col-md-4 col-sm-6">
						<label>Até a data:</label>
						{maxDate ? (
							<div className="input-group">
								<input type="date"
									onChange={onChange}
									name='ped_venda_date_fim' value={ped_venda_date_fim}
									min={ped_venda_date_init}
									max={maxDate}
									className={`form-control ${!ped_venda_date_fim && 'is-invalid'}`}
								/>
							</div>
						) : (
							<div className="input-group">
								<input type="date"
									onChange={onChange}
									name='ped_venda_date_fim' value={ped_venda_date_fim}
									min={ped_venda_date_init}
									className={`form-control ${!ped_venda_date_fim && 'is-invalid'}`}
								/>
							</div>
						)}
					</div>
				</>
			)}
			<div className='mb-3 col-md-3 col-sm-12'>
				<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-info">
					<input
						type="checkbox"
						className="custom-control-input"
						id="see_completed_works"
						onChange={() => handleForm({
							see_completed_works: !see_completed_works ? 1 : 0
						})}
						checked={see_completed_works}
					/>
					<label
						className="custom-control-label"
						htmlFor="see_completed_works"
					>
						Incluir na listagem as obras concluídas
					</label>
				</div>
			</div>
		</div >
	)
}
export default FilterConstructionPlan