import { handleExportXLS } from '../../components/excel'
import { colorsToChartPercentageExecution } from '../../constants'
import { formatDate, formateHours, generateDateTimeStampJS } from '../dateHandless'
import { handleWaring } from '../isValid'
import * as XLSX from 'xlsx'
import { convertToTitleCase, searchSomeWordInString, splitString } from '../stringHandless'

const handleExportReport = ({
    report,
    setInfoRequest,
}) => {
    try {
        const { data } = report
        if (!Array.isArray(data)) {
            return setInfoRequest(handleWaring('Não há NCs no relatório para serem exportadas'))
        }
        const result = []
        for (const item of data) {
            const { fiscalizacao } = item
            result.push({
                'ID': item.sisf_sinc_id || item.ped_venda_cod,
                'DATA FISCALIZACAO': formatDate(item.ped_venda_date),
                'HORA FISCALIZACAO': formateHours(item.ped_venda_date),
                'RODOVIA': item.rod_desc,
                'CONCESSIONARIA': item.conc_nome,
                'LOTE': item.lot_conc_desc,
                'CODIGO RODOVIA': item.rod_km,
                'KM INICIAL': item.pv_loc_km_ini,
                'M INICIAL': item.pv_loc_km_init_mts,
                'KM FINAL': item.pv_loc_km_fim,
                'M FINAL': item.pv_loc_km_fim_mts,
                'SENTIDO': item.rod_sent_desc,
                'DATA RETORNO': '',
                'STATUS RETORNO': item.st_desc,
                'TIPO ATIVIDADE': item.serv_desc,
                'GRUPO ATIVIDADE': item.serv_cat_desc,
                'ATIVIDADE': item.pvs_obs,
                'NUMERO NOTIFICACAO': '',
                'DATA ENVIO': item.ped_venda_date,
                'DATA REPARO': item.pvs_prazo_fim,
                'RESPONSAVEL TECNICO': item.name,
                'HORA ENVIO': item.ped_venda_date,
                'LATITUDE': item.pv_loc_lat,
                'LONGITUDE': item.pv_loc_lng,
                'DATA FISC': '',
                'CONCESSIONÁRIA GERAL': '',
            })
            if (fiscalizacao?.length) {
                for (const fisc of fiscalizacao) {
                    result.push({
                        'ID': item.sisf_sinc_id || item.ped_venda_cod,
                        'DATA FISCALIZACAO': '',
                        'HORA FISCALIZACAO': '',
                        'RODOVIA': '',
                        'CONCESSIONARIA': '',
                        'LOTE': '',
                        'CODIGO RODOVIA': '',
                        'KM INICIAL': '',
                        'M INICIAL': '',
                        'KM FINAL': '',
                        'M FINAL': '',
                        'SENTIDO': '',
                        'DATA RETORNO': '',
                        'STATUS RETORNO': fisc.fisc_obs,
                        'TIPO ATIVIDADE': '',
                        'GRUPO ATIVIDADE': '',
                        'ATIVIDADE': '',
                        'NUMERO NOTIFICACAO': '',
                        'DATA ENVIO': '',
                        'DATA REPARO': '',
                        'RESPONSAVEL TECNICO': fisc.name,
                        'HORA ENVIO': '',
                        'LATITUDE': fisc.fisc_lat,
                        'LONGITUDE': fisc.fisc_lng,
                        'DATA FISC': formatDate(fisc.created_at),
                        'CONCESSIONÁRIA GERAL': '',
                    })
                }
            }
        }
        const headers = Object.keys(result[0]).map(item => ({
            label: item.replace(/_/g, ' '),
            key: item
        }))
        const csvdata = {
            data: result,
            headers: headers,
            fileName: `Relatório-${formatDate(new Date())}`,
        }
        handleExportXLS({ setInfoRequest, data: csvdata.data, headers: csvdata.headers, fileName: csvdata.fileName })
    } catch (error) {
        console.log(error)
    }
}
/**
 * Exports an array to an Excel file.
 * @param {Array<Object>} dataArray - Array of objects to be exported to Excel.
 * @param {string} filename - Name of the Excel file to be generated (without the .xlsx extension).
 * @param {Array<string>} [header] - Optional. Array of strings representing the custom header for the Excel file.
 * @returns {void}
 */
const exportToExcel = (dataArray, filename, header) => {
    if (!dataArray || !filename || !header) {
        return false
    }
    const temp_header = header.map(item => item.key)
    const ws = XLSX.utils.json_to_sheet(dataArray, { header: [...temp_header] })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, String(filename).slice(0, 5))
    XLSX.writeFile(wb, `${String(filename).slice(0, 5)}-${generateDateTimeStampJS()}.xlsx`)
    return true
}
const readSpreedSheet = async (file) => {
    try {
        if (!file) {
            throw new Error("Você precisa especificar o arquivo");
        }
        const xlsrows = await new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
                const data = event.target.result;
                const workbook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const arrayData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                resolve(arrayData);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsBinaryString(file);
        });
        return xlsrows
    } catch (error) {
        console.log(error)
        return null
    }
}
/**
 * Reads an Excel file and converts it into an array of data.
 * @param {File} file - The Excel file to import.
 * @returns {Promise<Array<Array<any>>>} A promise that resolves to an array containing the data from the Excel file.
 */
const importExcelToArray = async (file) => {
    try {
        if (!file) {
            throw new Error("Você precisa especificar o arquivo");
        }
        const xlsrows = await readSpreedSheet(file)
        if (!xlsrows) {
            throw new Error("Não foi possível ler o arquivo");
        }
        const filteredRows = xlsrows.filter(item => item?.length);
        return filteredRows;
    } catch (error) {
        console.log(error)
        return []
    }
}
const readSpreedSheetOfNewConstructionPlan = async ({
    file,
    setProgress
}) => {
    try {
        let progress = 0
        const xlsrows = await readSpreedSheet(file)
        const pl_ob_desc_objet = xlsrows[1][1]
        const items = xlsrows.slice(11)
        if (!items.length) {
            throw new Error("Você precisa especificar os items que serão executados na obra");
        }
        let discipline = ''
        let disciplineName = ''
        const services = []
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const [
                discipline_cod = '',
                reduced_sub_item_name = '',
                unit_measurement = '',
                expected_quantity = 0,
                unit_price = 0,
                total_price = 0,
            ] = item
            let iscorrectlyFilledIn = true
            if (
                (typeof expected_quantity !== 'number') || (typeof unit_price !== 'number') ||
                (typeof unit_measurement !== 'string') || (typeof reduced_sub_item_name !== 'string')) {
                iscorrectlyFilledIn = false
            }
            const targetString = 'CÓDIGO DA FASE - '
            const wordsToSearch = splitString(targetString, ' ')
            const number_of_displine = Number(String(discipline_cod).replace(targetString, ''))
            const isDisciplineCod = searchSomeWordInString(discipline_cod, wordsToSearch)
            if (!isNaN(number_of_displine) && isDisciplineCod) {
                disciplineName = convertToTitleCase(reduced_sub_item_name)
                discipline = number_of_displine
                continue
            } else {
                discipline = null
            }
            services.push({
                prog_serv_obra: '0000',
                serv_desc: disciplineName,
                pvs_obs: discipline || discipline_cod,
                serv_sub_cat_desc: reduced_sub_item_name,
                pvs_qtd: expected_quantity,
                pvs_valor: unit_price,
                pvs_valor_total: total_price,
                ob_serv_med_desc: unit_measurement,
                prog_serv_qtd_acul: 0,
                prog_serv_qtd_acul_ant: 0,
                prog_serv_percen_progress: 0,
                prog_serv_percen_acul_ant: 0,
                prog_serv_percen_acul: 0,
                st_desc: 'Pendente',
                st_cor_valor: colorsToChartPercentageExecution[1],
                iscorrectlyFilledIn
            })
            progress = ((i + 1) / items.length) * 100
            if (setProgress) {
                setProgress(progress)
            }
        }
        return {
            services: services,
            pl_ob_desc_objet,
        }
    } catch (error) {
        console.log(error)
        throw error;
    }
}
export {
    exportToExcel,
    handleExportReport,
    importExcelToArray,
    readSpreedSheetOfNewConstructionPlan
}
