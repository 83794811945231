import React, { useContext, useEffect, useRef, useState } from 'react'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import getLatLng from '../../../services/geolocation'
import { handleWaring, isValid } from '../../../services/isValid'
import {
	formatDate,
	formateValidatedDate,
} from '../../../services/dateHandless'
import { getExt, generateBase64 } from '../../../services/files'
import Camera from '../../camera'
import { styleButtonPlus, styleButtonSearch } from '../../../css/globlalStyle'
import Spinner from '../../spinner'
import ImageItem from '../img'
import { scrollToElement } from '../../../services/scroll'
import HighwayExtension from '../../form/highwayExtension'
import SelectCardinalPoints from '../../form/selectCardinalPoints'
import { config } from '../../../constants'
import { find } from '../../../services/arrayHandless'
export const RenderServicoInfo = ({ item, edit }) => {
	return (
		<>
			{item?.pvs_obs && !item.serv_sub_cat_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					{item?.pvs_obs}
				</div>
			)}
			{item?.serv_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					<span className="text-secondary mr-2">
						Tipo atividade:
					</span>
					{item?.serv_desc}
				</div>
			)}
			{item?.serv_cat_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					<span className="text-secondary mr-2">
						Grupo da atividade:
					</span>
					{item?.serv_cat_desc}
				</div>
			)}
			{item.serv_sub_cat_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					<span className="text-secondary mr-2">
						Atividade:
					</span>
					{item?.serv_sub_cat_desc}
				</div>
			)}
			<div className="form-group col-md-4 col-sm-12">
				<label className="text-secondary">Status:</label>
				<div className="input-group">
					{item?.wait_update ? (
						<div
							className="spinner-border spinner-border-sm text-danger mr-2 p-2"
							role="status"
						>
							<span className="sr-only"></span>
						</div>
					) : (
						<span
							className="text-light badge"
							style={{ backgroundColor: item.st_cor_valor }}
						>
							{item.st_desc}
						</span>
					)}
				</div>
			</div>
			<div className="col-md-6 col-sm-6">
				<div className="form-group">
					<label className="text-secondary">Prazo:</label>
					<div className="input-group">
						<div className={edit ? 'mr-3' : 'm-0'}>
							{item.wait_update
								? formatDate(`${item.pvs_prazo_fim} 00:00:00`)
								: item.pvs_prazo_fim &&
								formatDate(item.pvs_prazo_fim.replace('000Z', ''))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export const RenderLocation = ({ item }) => {
	if (!item) {
		return <p className="text-secondary">Não foi possível carregar a localização</p>
	}
	return (
		<>
			<div className="col-6 mb-3">
				<span className="text-secondary">KM Inicial: </span>{' '}
				{item.pv_loc_km_ini}, {item.pv_loc_km_init_mts} mt.
			</div>
			<div className="col-6 mb-3">
				<span className="text-secondary">KM Final: </span>{' '}
				{item.pv_loc_km_fim},   {item.pv_loc_km_fim_mts} mt.
			</div>
			<div className="col-6 mb-3">
				<span className="text-secondary">Sentido: </span>
				{item.rod_sent_desc}
			</div>
			<div className="col-6 mb-3">
				<span className="text-secondary">Complementos: </span>{' '}
				{item.pv_loc_obs}
			</div>

		</>
	)
}
const ListServiceOversight = ({ data }) => {
	const {
		deleteItemOversightService,
		postItemOversightService,
		getItemHighway,
		getItemCardinalPoints,
		getItemComplements,
		postItemLocationOversight,
		setInfoRequest,
		isConection,
		status,
		highway,
		setLoading,
		setHighway,
	} = useContext(requestContext)
	const {
		showCamera,
		setShowCamera,
		handleForm,
		state: stateFromContext,
	} = useContext(formContext)
	const { user } = useContext(authContext)
	const changeDeadline = useRef()
	const [edit, setEdit] = useState(false)
	const [editLocation, setEditLocation] = useState(false)
	const [enableChangeDeadline, setEnableChangeDeadline] = useState(false)
	const [enableChangeStatus, setEnableChangeStatus] = useState(false)
	const [pvs_status, setPvsStatus] = useState(0)
	const [minSelectedDate, setMinSelectedDate] = useState(null)
	const pvs_prazo_fim_ref = useRef()
	const [itemsStatus, setItemsStatus] = useState(null)
	const [bases64, setBases64] = useState(null)
	const [valuesFixed, setValuesFixed] = useState(null)
	const [state, setState] = useState({
		os_obs_prazo: '',
		pvs_prazo_fim: '',
		ane_pl_ob_obs: '',
		st_desc: '',
		pv_loc_obs: '',
	})
	const { os_obs_prazo, pvs_prazo_fim, ane_pl_ob_obs, st_desc, pv_loc_obs } = state
	const [configPedVenda, setConfigPedVenda] = useState(null)
	useEffect(() => {
		const loadConfig = async () => {
			const response_config = await config('ped_venda_status_pendente')
			if (response_config) {
				setConfigPedVenda(response_config)
			}
		}
		loadConfig()
	}, [])
	useEffect(() => {
		const loadValueFixedHighway = () => {
			if (Array.isArray(highway?.data)) {
				const { data: highway_data } = highway
				highway_data.forEach(item => (
					setValuesFixed(item)
				))
			}
		}
		loadValueFixedHighway()
	}, [highway])
	useEffect(() => {
		if (enableChangeDeadline && changeDeadline) {
			scrollToElement(changeDeadline.current)
		}
	}, [enableChangeDeadline, changeDeadline])
	useEffect(() => setMinSelectedDate(formateValidatedDate()), [])
	useEffect(() => {
		if (Array.isArray(status?.data)) {
			const { data } = status
			const filtered = data.filter(item => item.st_cod !== 2)
				.filter(item => item.st_cod !== 3).filter(item => item.st_cod !== 5)
			setItemsStatus(filtered)
		}
	}, [status])
	const input_file_ref = useRef()
	useEffect(() => {
		handleForm({
			ane_pl_ob_obs: '',
			help_desc: '',
		})
		handleForm({
			ane_pl_ob_obs: '',
		})
		setBases64(null)
	}, [])
	useEffect(() => {
		const getStatusDesc = () => {
			if (Array.isArray(itemsStatus)) {
				const findStatus = itemsStatus.find(item => item.st_cod === parseInt(pvs_status))
				if (findStatus) {
					onChange({
						target: {
							name: 'st_desc',
							value: findStatus.st_desc
						}
					})
				}
			}
		}
		if (pvs_status) {
			getStatusDesc()
		}
	}, [itemsStatus, pvs_status])
	const handleOnChange = ({ target }) => {
		const value = target.value
		setPvsStatus(Number(value))
	}
	const onChange = ({ target }) => {
		const { value, name } = target
		setState({
			...state,
			[name]: value,
		})
	}
	const handleDelete = async (item) => {
		const confirm = window.confirm(
			`Deseja mesmo remover o serviço ${item.pvs_obs || item?.serv_sub_cat_desc} ?`
		)
		if (confirm) {
			const fisc_obs = window.prompt(
				`Explique o porque você está cancelando o serviço ${item.pvs_obs || item?.serv_sub_cat_desc} para concessionária ${item.conc_nome}`
			)
			if (!fisc_obs) {
				return setInfoRequest(
					handleWaring(
						`Você precisa explicar o porque deseja Cancelar o serviço ${item.pvs_obs || item?.serv_sub_cat_desc} para concessionaria ${item.conc_nome}`
					)
				)
			}
			if (fisc_obs.length > 500) {
				return setInfoRequest(
					handleWaring('Você não pode colocar mais do que 500 caracteres')
				)
			}
			if (fisc_obs.length < 5) {
				return setInfoRequest(
					handleWaring('Você precisa colocar mais do que 5 caracteres')
				)
			}
			const postion = isConection ? await getLatLng() : { lat: 0, lng: 0 }
			deleteItemOversightService({
				id: item.pvs_pedidovenda,
				fisc_obs,
				fisc_lat: postion.lat,
				fisc_lng: postion.lng,
			})
		}
	}
	const onSubmitStatus = async (item) => {
		if (item.pvs_status === 1) {
			setEnableChangeStatus(false)
			return setInfoRequest(handleWaring(`Atividade está ${status.st_desc} não é possível mudar a situação.`))
		}
		const confirm = window.confirm(
			`Deseja mesmo alterar a situação do serviço ${item.pvs_obs} ?`
		)
		if (!confirm) {
			return
		}
		const { lat: fisc_lat, lng: fisc_lng
		} = isConection ? await getLatLng() : { lat: 0, lng: 0 }
		const pv_fotos = bases64.map(foto => ({
			...foto,
			pvs_loc_foto_pedidovenda: item.pvs_cod,
		}))
		const objectSubmit = {
			pvs_cod: item.pvs_cod,
			pvs_pedidovenda: item.pvs_pedidovenda,
			pvs_prazo_fim: item.pvs_prazo_fim,
			fisc_obs: '',
			pv_fotos: pv_fotos,
			pvs_status: pvs_status,
			fisc_lat: fisc_lat,
			fisc_lng: fisc_lng,
		}
		postItemOversightService({
			id: objectSubmit.pvs_cod,
			...objectSubmit
		}, () => handleCancel())
	}
	const onSubmitDeadline = async (item) => {
		if (item.pvs_status === 1) {
			setEnableChangeDeadline(false)
			return setInfoRequest(handleWaring(`Atividade está ${status.st_desc} não é possível mudar o prazo.`))
		}
		const confirm = window.confirm(
			`Deseja mesmo alterar o prazo do serviço ${item.pvs_obs} ?`
		)
		if (!confirm) {
			return
		}
		const { lat: fisc_lat, lng: fisc_lng
		} = isConection ? await getLatLng() : { lat: 0, lng: 0 }
		const objectSubmit = {
			pvs_cod: item.pvs_cod,
			pvs_pedidovenda: item.pvs_pedidovenda,
			pvs_prazo_fim: `${pvs_prazo_fim_ref.current.value} 11:59:00`,
			fisc_obs: os_obs_prazo,
			pv_fotos: [],
			pvs_status: item.pvs_status,
			fisc_lat: fisc_lat,
			fisc_lng: fisc_lng,
		}
		postItemOversightService({
			id: objectSubmit.pvs_cod,
			...objectSubmit
		}, () => {
			handleCancel()
			if (!configPedVenda) {
				return
			}
			const { config_para_value } = configPedVenda
			const { data: data_from_status } = status
			const { data: findStatus } = find(data_from_status, 'st_cod', parseInt(config_para_value))
			handleForm({
				ped_venda_status: parseInt(config_para_value),
				pvs_status: parseInt(config_para_value),
				...findStatus,
			})
		})
	}
	const handleCancel = () => {
		setState({
			os_obs_prazo: '',
			pvs_prazo_fim: '',
			st_desc: '',
			ane_pl_ob_obs: '',
		})
		setEdit(false)
		setEnableChangeDeadline(false)
		setEnableChangeStatus(false)
		setPvsStatus(0)
		setEdit(false)
		setEditLocation(false)
		handleForm({
			rod_km_fim: '',
			rod_km_fim_mt: '',
			rod_km_ini: '',
			rod_km_ini_mt: '',
			rod_sent_cod: '',
		})
	}
	const onvisibleCamera = () => {
		setShowCamera(!showCamera)
	}
	const [file, setFile] = useState({
		base64: '',
		fileName: '',
	})
	const onChangeFile = ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return setFile({
				fileName: '',
				base64: '',
			})
		}
		if (files.length > 1) {
			setFile({
				base64: files,
				fileName: `${files.length} adicionados`,
			})
		} else {
			const { name } = files[0]
			setFile({
				base64: files,
				fileName: name,
			})
		}
	}
	const handleAttachments = () => {
		input_file_ref.current.click()
	}

	const handleAdd = async () => {

		if (!file.base64 || !ane_pl_ob_obs) {
			return setInfoRequest(handleWaring('Você precisa anexar pelo menos uma foto e colocar uma observação sobre a foto'))
		}
		const newFiles = []
		setLoading(true)
		for (let i = 0; i < file.base64.length; i++) {
			const element = file.base64[i]
			const { name } = element
			const ext = getExt(name)
			const filegenerateBse64 = await generateBase64(element)
			newFiles.push({
				fileName: name,
				ane_pl_ob_path: filegenerateBse64,
				ane_pl_ob_obs,
				ext,
			})
		}
		newFiles.forEach(({ ane_pl_ob_path, ane_pl_ob_obs }) => {
			Array.isArray(bases64)
				? setBases64([
					...bases64,
					{
						base64: ane_pl_ob_path,
						pvs_loc_lat: 0,
						pvs_loc_lng: 0,
						pvs_loc_foto_legenda: ane_pl_ob_obs,
					},
				])
				: setBases64([
					{
						base64: ane_pl_ob_path,
						pvs_loc_lat: 0,
						pvs_loc_lng: 0,
						pvs_loc_foto_legenda: ane_pl_ob_obs,
					},
				])
		})
		handleForm({
			ane_pl_ob_obs: '',
		})

	}
	const handleResetValues = () => {
		setLoading(false)
		setFile({
			fileName: '',
			base64: '',
		})
		handleForm({
			ane_pl_ob_obs: '',
		})
		input_file_ref.current.value = null
	}
	const RenderStatus = () => {
		if (Array.isArray(itemsStatus)) {
			if (user.set_nivel === 3) {
				return itemsStatus.filter(item => item.st_desc === 'Executado (Pendente Aprovação)').map(item => (
					<option key={item.st_cod} value={item.st_cod}>{item.st_desc}</option>
				))
			}
			return (
				itemsStatus.map(item => (
					<option key={item.st_cod} value={item.st_cod}>{item.st_desc}</option>
				))
			)
		}
	}
	const handleEdit = (item) => {
		setState({
			...item
		})
		const { ped_venda_rodovia } = stateFromContext
		setHighway(null)
		getItemHighway(ped_venda_rodovia)
		getItemCardinalPoints(ped_venda_rodovia)
		getItemComplements()
		setEditLocation(true)
	}
	const handleSubmitLocation = async () => {
		const {
			rod_km_fim: pv_loc_km_fim,
			rod_km_fim_mt: pv_loc_km_fim_mts,
			rod_km_ini: pv_loc_km_ini,
			rod_km_ini_mt: pv_loc_km_init_mts,
			rod_sent_cod: pv_loc_sentido,
			ped_venda_cod: pv_loc_pedidovenda,
		} = stateFromContext
		let temp_pv_loc_sentido = pv_loc_sentido || state?.pv_loc_sentido
		const { pvs_cod } = state
		const fisc_obs = os_obs_prazo
		const position = await getLatLng()
		const pv_loc_lat = position.lat
		const pv_loc_lng = position.lng
		const item = {
			pv_loc_km_fim, pv_loc_km_fim_mts, pv_loc_km_ini, pv_loc_km_init_mts,
			pv_loc_sentido: temp_pv_loc_sentido, fisc_obs, pv_loc_obs, pv_loc_pedidovenda,
			pvs_cod
		}
		const valid = isValid(item)
		if (!valid) {
			return setInfoRequest(handleWaring())
		}
		item.pv_loc_cod = state.pv_loc_cod
		postItemLocationOversight({
			...item,
			id: item.pv_loc_cod,
			pv_loc_lat,
			pv_loc_lng
		})
		window.scrollTo(0, 0)
		handleCancel()
	}

	if (editLocation) {
		return (
			<div className="row">
				<HighwayExtension valuesFixed={valuesFixed} />
				<div className="form-group col-md-6 col-sm-12 mb-3" ref={changeDeadline}>
					<label>Motivo para alteração da localização:</label>
					<textarea
						type="text"
						className={`form-control ${!os_obs_prazo && 'is-invalid'
							}`}
						onChange={onChange}
						name="os_obs_prazo"
						value={os_obs_prazo}
						placeholder="Descreva brevemente o porque você está alterados os dados da localização"
					/>
				</div>
				<div className="form-group col-md-6 col-sm-12">
					<label>Complementos adicionados:</label>
					<div className="input-group">
						<textarea
							type="text"
							className={`form-control ${!pv_loc_obs && 'is-invalid'
								}`}
							onChange={onChange}
							name="pv_loc_obs"
							value={pv_loc_obs}
							maxLength={500}
							placeholder="Descreva brevemente sobre o local onde você está"
						/>
						<div className="input-group-prepend">
							<span
								className="input-group-text bg-info"
								style={styleButtonSearch}
								onClick={() => handleForm({
									pv_loc_obs: ''
								})}
							>
								<i className="fa fa-times-circle" aria-hidden="true"></i>
							</span>
						</div>
					</div>
				</div>
				<SelectCardinalPoints />
				<div className="col-12">
					<div className="row">
						<div className="col-md-6 col-sm-12">
							<div className="row">
								<div className="col-12 mb-3">
									<p className="text-secondary">
										Antes:
									</p>
								</div>
								{state?.pv_loc_cod && (
									<RenderLocation item={state} />
								)}
							</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<div className="row">
								<div className="col-12 mb-3">
									<p className="text-secondary">
										Depois:
									</p>
								</div>
								<RenderLocation item={{
									pv_loc_km_ini: stateFromContext?.rod_km_ini,
									pv_loc_km_init_mts: stateFromContext?.rod_km_ini_mt,
									pv_loc_km_fim: stateFromContext?.rod_km_fim,
									pv_loc_km_fim_mts: stateFromContext?.rod_km_fim_mt,
									rod_sent_desc: stateFromContext?.rod_sent_desc,
									pv_loc_obs: stateFromContext?.pv_loc_obs
								}} />
							</div>
						</div>
					</div>
				</div>
				<div className="col-12">
					<button className="btn btn-info mr-2" onClick={handleSubmitLocation}>
						<i className="fa fa-save"></i> Salvar
					</button>
					<button className="btn btn-secondary" onClick={handleCancel}>
						<i className="fa fa-times-circle"></i> Cancelar
					</button>
				</div>
			</div>
		)
	}
	return (
		data?.total > 0 &&
		data.data.map((item) => (
			<div className="row" key={item.pvs_cod}>
				{item.wait_update && item.obs && (
					<div className="col-sm-6 col-md-4 mb-2">
						{item.obs}
					</div>
				)}
				<RenderServicoInfo item={item} edit={edit} />
				{enableChangeDeadline && (
					<>
						<div className="form-group col-md-6 col-sm-12 mb-3" ref={changeDeadline}>
							<label>Motivo para alteração do prazo:</label>
							<textarea
								type="text"
								className={`form-control ${!os_obs_prazo && 'is-invalid'
									}`}
								onChange={onChange}
								name="os_obs_prazo"
								value={os_obs_prazo}
								placeholder="Descreva brevemente o porque você está colocando um prazo diferente do anterior"
							/>
						</div>
						<div className="col-md-6 col-sm-6 mb-3">
							<div className="form-group">
								<label className="text-secondary">Atualizar Prazo:</label>
								<div className="input-group">
									<input
										type="date"
										className="form-control"
										value={pvs_prazo_fim}
										name="pvs_prazo_fim"
										min={minSelectedDate}
										ref={pvs_prazo_fim_ref}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
					</>
				)}
				{enableChangeStatus && (
					<div className="col-12">
						<div className="form-group">
							<label className="text-secondary mr-2">Retornar como:</label>
							<div className="input-group">
								<select
									className="form-control"
									onChange={handleOnChange}
									value={pvs_status}
								>
									<option value={0}>Selecionar</option>
									<RenderStatus />
								</select>
								<div className="input-group-prepend">
									<span
										className="input-group-text bg-info"
										onClick={() => onSubmitStatus(item)}
										style={styleButtonPlus}
									>
										<i className="fa fa-save" aria-hidden="true"></i>
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
				{pvs_status && showCamera ? <Camera setImages={setBases64} images={bases64}
					pvs_loc_foto_legenda={`Foto tirada referente ao serviço ${item.pvs_obs}`} /> : ''}
				{pvs_status ? (
					<>
						<div className="col-12">
							Tire ou anexe uma foto que comprova que o serviço está {st_desc}
						</div>
						<div className="form-group col-md-4 col-sm-5">
							<label>Anexar:</label>
							<div className="input-group">
								<div className="btn btn-dark btn-sm" onClick={handleAttachments}>
									Clique aqui para selecionar uma foto <i className="fa fa-paperclip"></i> {file.fileName}
								</div>
							</div>
						</div>
						{file && (
							<div className="form-group col-12">
								<label>Observações da foto:</label>
								<div className="input-group">
									<textarea
										type="text"
										onChange={onChange}
										rows={2}
										name="ane_pl_ob_obs"
										value={ane_pl_ob_obs}
										className={`form-control ${!ane_pl_ob_obs && 'is-invalid'
											}`}
										placeholder="Coloque aqui informações sobre a foto"
									>
										{ane_pl_ob_obs}
									</textarea>
									<div className="input-group-prepend">
										<span
											className="input-group-text bg-info"
											onClick={() =>
												handleAdd().finally(() => {
													handleResetValues()
												})
											}
											style={styleButtonPlus}
										>
											<Spinner label=" " />
											<i className="fa fa-plus" aria-hidden="true"></i>
										</span>
									</div>
								</div>
							</div>
						)}
						<div className="form-group col-12">Ou</div>
						<div className="form-group col-md-4 col-sm-5">
							<label>Tire uma foto:</label>
							<div className="input-group">
								<div className="btn btn-dark btn-sm" onClick={onvisibleCamera}>
									Clique aqui para abrir a câmera <i className="fa fa-camera"></i>
								</div>
							</div>
						</div>
						<input
							name="file_attachments"
							className="d-none"
							type="file"
							onChange={onChangeFile}
							ref={input_file_ref}
							multiple
						/>
					</>
				) : ''}
				{pvs_status && bases64 ?
					<div className="col-12">
						{Array.isArray(bases64) && (
							<div
								className="row"
								id="imagems"
							>
								{bases64.map((item, i) => (
									<div key={i} className="col-md-4 col-sm-12">
										<ImageItem
											byPassSetLevel
											removePhoto={setBases64}
											key={item}
											src={item.base64}
											data={bases64}
											legend={item.pvs_loc_foto_legenda}
										/>

									</div>
								))}
							</div>
						)}
					</div>
					: ''}
				<RenderLocation item={item} edit={edit} />
				{item.pvs_status !== 1 && (
					<>
						{enableChangeDeadline && (
							<div className="col-md-3 col-sm-6" onClick={() => onSubmitDeadline(item)}>
								<button type="button" className="btn btn-info">
									<i className="fa fa-save"></i> Salvar alteração
								</button>
							</div>
						)}
						<div className="col-md-3 col-sm-6">
							{enableChangeDeadline ? (
								<button className="btn btn-secondary" onClick={() => setEnableChangeDeadline(!enableChangeDeadline)}>
									<i className="fa fa-times-circle"></i> Cancelar alteração
								</button>
							) : (
								!enableChangeStatus && (
									<button className="btn btn-info" onClick={() => setEnableChangeDeadline(!enableChangeDeadline)}>
										<i className="fa fa-calendar mr-2"></i> Alterar prazo
									</button>
								)
							)}
						</div>
						{!enableChangeDeadline && (
							<div className="col-md-3 col-sm-6">
								{enableChangeStatus ? (
									<button className="btn btn-dark" onClick={() => setEnableChangeStatus(!enableChangeStatus)}>
										<i className="fa fa-edit"></i> Cancelar alteração
									</button>
								) : (
									<button className="btn btn-dark" onClick={() => setEnableChangeStatus(!enableChangeStatus)}>
										<i className="fa fa-edit"></i> Retornar como
									</button>
								)}
							</div>
						)}
						{
							!enableChangeDeadline && (
								<div className="col-md-3 col-sm-6 mb-3">
									<button className="btn btn-secondary" onClick={() => handleDelete(item)}>
										<i className="fa fa-trash"></i> Excluir atividade
									</button>
								</div>
							)
						}
						{!enableChangeDeadline && (
							<div className="col-md-3 col-sm-6">
								<button className="btn btn-info" onClick={() => handleEdit(item)}>
									<i className="fa fa-edit"></i> Editar a localização
								</button>
							</div>
						)}
					</>
				)}
				<hr className="w-100" />
			</div >
		))
	)
}
export default ListServiceOversight
