import React, { useContext } from 'react'
import { formatDate } from '../../../services/dateHandless'
import { requestContext } from '../../../context/requestContext'
import { handleWaring } from '../../../services/isValid'
import getLatLng from '../../../services/geolocation'
import { formContext } from '../../../context/formContext'
import RenderActive from '../oversight/renderActive'
import ButtonOpenMap from '../../map/buttonOpenMap'
import { authContext } from '../../../context/authContext'
import RenderKMLocation from '../oversight/renderKMLocation'

const ListCheckinspector = () => {
	const {
		check_inspection: items,
		postApproveCheckcInspection,
		setInfoRequest
	} = useContext(requestContext)
	const { state } = useContext(formContext)
	const { user } = useContext(authContext)
	const {
		serv_desc,
		serv_cat_desc,
		serv_sub_cat_desc,
		pvs_prazo_fim,
	} = state
	const handleAprovedFisc = async (item) => {
		const { fisc_cod, fisc_ped_venda: ped_venda_cod, created_at, approved } = item
		let fisc_obs = ''
		if (!approved) {
			fisc_obs = window.prompt('Explique o porque você não aprovou essa alteração.')
			if (fisc_obs?.length > 100 || fisc_obs?.length < 5) {
				return setInfoRequest(handleWaring('Você deve escrever a explicação com minimo 5 e máximo 100 caracteres '))
			}
		}
		const { lat: fisc_lat, lng: fisc_lng } = await getLatLng()
		postApproveCheckcInspection({
			ped_venda_cod,
			approved,
			fisc_obs,
			fisc_cod,
			fisc_lat,
			fisc_lng,
			created_at,
		})
	}
	const RenderButtonAprovedFisc = ({ item }) => {
		try {
			const { fisc_adicional_parametros } = item
			const parse_fisc_adicional_parametros = JSON.parse(fisc_adicional_parametros)
			if (!parse_fisc_adicional_parametros?.type_of_change || user?.set_nivel !== 0) {
				return (
					<div className='col-12'>
					</div>
				)
			}
			return (
				<>
					{parse_fisc_adicional_parametros?.type_of_change === 1 ? (
						<>
							<div className='text-secondary col-12'>Antes da alteração</div>
							<RenderActive item={{
								...parse_fisc_adicional_parametros
							}} />
							<div className='col-12 mb-3'>
								<span className='text-secondary'>
									Prazo:
								</span>
								{formatDate(parse_fisc_adicional_parametros?.pvs_prazo_fim)}
							</div>
							<div className='text-secondary col-12'>Depois da alteração</div>
							<RenderActive item={{
								serv_desc,
								serv_cat_desc,
								serv_sub_cat_desc,
							}} />
							<div className='col-12 mb-3'>
								<span className='text-secondary'>
									Prazo:
								</span>
								{formatDate(pvs_prazo_fim)}
							</div>

						</>
					) : null}
					{parse_fisc_adicional_parametros?.type_of_change === 2 ? (
						<>
							<div className='text-secondary col-12'>Antes da alteração</div>
							<RenderKMLocation
								item={{
									pv_loc_km_ini: parse_fisc_adicional_parametros.temp_pv_loc_km_ini,
									pv_loc_km_init_mts: parse_fisc_adicional_parametros.temp_pv_loc_km_init_mts,
									pv_loc_km_fim: parse_fisc_adicional_parametros.temp_pv_loc_km_fim,
									pv_loc_km_fim_mts: parse_fisc_adicional_parametros.temp_pv_loc_km_fim_mts,
								}}
								className={'mb-3 col-md-6 col-sm-12'}
							/>
							<div className='text-secondary col-12'>Depois da alteração</div>
							<RenderKMLocation
								item={{
									pv_loc_km_ini: parse_fisc_adicional_parametros.pv_loc_km_ini,
									pv_loc_km_init_mts: parse_fisc_adicional_parametros.pv_loc_km_init_mts,
									pv_loc_km_fim: parse_fisc_adicional_parametros.pv_loc_km_fim,
									pv_loc_km_fim_mts: parse_fisc_adicional_parametros.pv_loc_km_fim_mts,
								}}
								className={'mb-3 col-md-6 col-sm-12'}
							/>
						</>
					) : null}
					<div className='mb-3 col-md-6 col-sm-12'>
						<button className="btn btn-block btn-success btn-xs" onClick={() => handleAprovedFisc({
							...item,
							approved: true
						})}>
							<i className='fa fa-check'></i> Aprovar alteração
						</button>
					</div>
					<div className='mb-3 col-md-6 col-sm-12'>
						<button className="btn btn-block btn-info btn-xs" onClick={() => handleAprovedFisc({
							...item,
							approved: false
						})}>
							<i className='fa fa-times-circle'></i> Recusar alteração
						</button>
					</div>
				</>
			)
		} catch (error) {
			<div className='d-none'>{JSON.stringify(error)}</div>
		}
	}
	if (!items?.data?.length) {
		return <div className='text-secondary'>
			Não há fiscalizações para serem exibidas
		</div>
	}
	return (
		items?.data?.map(item => (
			<div className='row' key={item.fisc_cod}>
				<div className='mb-3 col-md-4 col-sm-12'>
					<span className='text-secondary mr-2'>Fiscalizado no dia: </span>
					{formatDate(item.created_at)}
					{(item.fisc_lat && item.fisc_lng) ? (
						<ButtonOpenMap
							lat={item.fisc_lat}
							lng={item.fisc_lng}
						/>
					) : null}
				</div>
				<div className='mb-3 col-md-8 col-sm-12'>
					<span className='text-secondary mr-2'>Descrição:</span>
					{item.fisc_obs}
				</div>
				<RenderButtonAprovedFisc item={item} />
				<hr className='col-12' />
			</div >
		))
	)
}
export default ListCheckinspector