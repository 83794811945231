import React, { useContext, useEffect, useRef, useState } from "react";
import { formContext } from "../../../context/formContext";
import { handleError, handleWaring, isValid } from "../../../services/isValid";
import { requestContext } from "../../../context/requestContext";
import { getUniqueRandomInt } from "../../../services/randomId";
import { divideMonthByPercentage, filterAndRemove } from "../../../services/arrayHandless";
import { useLocation } from "react-router-dom";
import { namesScreens } from "../../../constants";

const FormMonitoringProgressConstructionPlan = ({ monitoringProgressItems, setMonitoringProgressItems }) => {
    const { state, onChange, handleForm } = useContext(formContext)
    const { setInfoRequest, postItemMonitoringProgress } = useContext(requestContext)
    const [showButtonSave, setShowButtonSave] = useState(true)
    const { pathname } = useLocation()
    useEffect(() => {
        const controllShowButtoSave = () => {
            if (pathname === namesScreens.CONSTRUCTION_PLANNEW) {
                return setShowButtonSave(false)
            }
            return setShowButtonSave(true)
        }
        controllShowButtoSave()
    }, [pathname])
    const refElement = useRef()
    const {
        pl_ob_data_program_ini,
        pl_ob_data_program_fim,
        acob_perc_prev,
        acob_date_prev,
        acob_date_prev_fim,
        pl_ob_cod,
        acob_cod,
    } = state
    const handleSave = async () => {
        const payload = {
            id: acob_cod,
            pl_ob_data_program_ini,
            pl_ob_data_program_fim,
            acob_date_prev,
            acob_perc_prev,
            acob_pl_ob_cod: pl_ob_cod,
            acob_date_prev_fim,
        }
        if (Number(acob_perc_prev) > 100) {
            return setInfoRequest(handleWaring('O valor % do cronograma é mais de 100%, preencha o valor corretamente'))
        }
        const valid = isValid(payload)
        if (!valid) {
            return setInfoRequest(handleWaring('Você deve preencher todos os campos'))
        }
        postItemMonitoringProgress({
            ...payload,
        })
    }

    useEffect(() => {
        const clearState = () => {
            handleForm({
                acob_date_prev: '',
                acob_date_prev_fim: '',
                acob_perc_prev: '',
            })
        }
        clearState()
    }, [monitoringProgressItems])
    const handleAdd = async () => {
        const item = {
            id: getUniqueRandomInt(),
            acob_pl_ob_cod: "-",
            acob_date_prev,
            acob_date_prev_fim,
            acob_perc_prev,
        }
        if (monitoringProgressItems?.length) {
            const find = monitoringProgressItems.find(item => (item.acob_date_prev === acob_date_prev) && (item.acob_date_prev_fim === acob_date_prev_fim))
            if (find) {
                const { id } = find
                const { data: items } = filterAndRemove(monitoringProgressItems, 'id', id)
                delete item.id
                return setMonitoringProgressItems([...items, { ...item, id }])
            }
        }
        setMonitoringProgressItems([...monitoringProgressItems, { ...item }])

    }
    const handleAutomatic = () => {
        try {
            const monitoring_progress = divideMonthByPercentage({ dateInit: pl_ob_data_program_ini, dateFinal: pl_ob_data_program_fim })
            if (!Array.isArray(monitoring_progress)) {
                throw monitoring_progress
            }
            setMonitoringProgressItems(monitoring_progress)
        } catch (error) {
            setInfoRequest(handleError(String(error).replace('Error: ', '')))
        }
    }
    const handleClear = () => {
        setMonitoringProgressItems([])
    }
    return (
        <div className="row" ref={refElement}>
            <div className="form-group col-md-4 col-sm-4 mb-3">
                <label>Data programado inicial:</label>
                <div className="input-group">
                    <input
                        onChange={onChange}
                        type="date"
                        value={acob_date_prev}
                        name="acob_date_prev"
                        className={'form-control'}
                    />
                </div>
            </div>
            <div className="form-group col-md-4 col-sm-4 mb-3">
                <label>Data programado final:</label>
                <div className="input-group">
                    <input
                        onChange={onChange}
                        type="date"
                        value={acob_date_prev_fim}
                        name="acob_date_prev_fim"
                        className={'form-control'}
                    />
                </div>
            </div>
            <div className="form-group col-md-4 col-sm-4 mb-3">
                <label>% programada:</label>
                <div className="input-group">
                    <input
                        onChange={onChange}
                        type="number"
                        value={acob_perc_prev}
                        name="acob_perc_prev"
                        className={'form-control'}
                        placeholder="0"
                        min={0}
                        max={100}
                    />
                </div>
            </div>
            <div className="col-12 mb-3">
                <button className="btn btn-info mr-2" onClick={handleAdd}>
                    <i className="fa fa-plus"></i> Adicionar
                </button>
                <button className="btn btn-secondary mr-2" onClick={() => handleAutomatic()}>
                    <i className="fa fa-cog"></i> Gerar automáticamente
                </button>
                <button className="btn btn-danger" onClick={() => handleClear()}>
                    <i className="fa fa-trash"></i> Limpar
                </button>
                {showButtonSave ? (
                    <button className="btn btn-info" onClick={handleSave}>
                        <i className="fa fa-save"></i> Salvar
                    </button>
                ) : null}
            </div>
        </div>
    )
}
export default FormMonitoringProgressConstructionPlan