export function getUniqueRandomInt(min = 0, max = 100) {
	const usedNumbers = new Set();
	return UniqueRandomInt({ max, min, usedNumbers })

}
const UniqueRandomInt = ({ max, min, usedNumbers }) => {
	try {
		if (usedNumbers.size >= max - min) {
			throw new Error("Todos os números possíveis já foram gerados.");
		}

		let randomNumber;
		do {
			randomNumber = Math.floor(Math.random() * (max - min) + min);
		} while (usedNumbers.has(randomNumber));

		usedNumbers.add(randomNumber);
		return randomNumber;
	} catch (error) {
		console.log(error)
		return 0
	}
}
